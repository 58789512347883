import NoDataComponent from "components/NoDataComponent/NoDataComponent";
import SkeletonLoading from "components/SkeletonLoading/SkeletonLoading";
import ItemMatch from "./ItemMatch";

const WrapLeague = ({ data, loading, leagueActive }: any) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between bg-success-500/20 dark:bg-success-500/10 p-2.5">
        {loading ? (
          <SkeletonLoading height={20} width={240} />
        ) : (
          <h5 className="text-black dark:text-white font-semibold text-base">
            Lịch thi đấu {leagueActive}
          </h5>
        )}
      </div>
      <div className="flex flex-col h-full transition-all duration-500 overflow-hidden">
        {loading ? (
          Array.from({ length: 10 }).map((_, index: number) => (
            <SkeletonLoading height={40} width="100%" key={index} />
          ))
        ) : data?.length > 0 ? (
          data?.map((match: any, index: number) => (
            <div className="mt-3 w-full" key={index}>
              <ItemMatch data={match} />
            </div>
          ))
        ) : (
          <NoDataComponent title="Không tìm thấy trận đấu nào!" />
        )}
      </div>
    </div>
  );
};

export default WrapLeague;
