import { Card, CardBody, Typography, Avatar } from "@material-tailwind/react";
import postApi from "apis/postApi";
import { CommentComponentI } from "interfaces/post.interface";
import { Fragment, useEffect, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { vi } from "date-fns/locale";
import CommentForm from "./components/CommentForm";
import PaginationComponent from "components/PaginationComponent/PaginationComponent";
function CommentComponent({ postId }: CommentComponentI) {
  const [comments, setComments] = useState<Comment[]>([]);

  const [pagi, setPagi] = useState<any>({
    pageCount: 0,
    currentPage: 0,
    totalPages: 0,
  });

  useEffect(() => {
    return () => {
      setPagi({
        pageCount: 0,
        currentPage: 0,
        totalPages: 0,
      });
    };
  }, []);

  useEffect(() => {
    if (postId) {
      handleGetCommentByPost();
    }
  }, [postId]);

  const handleGetCommentByPost = async (page?: number) => {
    const response = await postApi.getCommentByPost({
      post: postId,
      per_page: 5,
      page: Number(page) + 1 || 1,
    });
    const { data } = response;
    if (data) {
      setComments(data);
      const pageCount = response.headers["x-wp-totalpages"];
      const totalPages = parseInt(response.headers["x-wp-total"], 10);
      setPagi({
        pageCount,
        currentPage: Number(page) || 0,
        totalPages,
      });
    }
    try {
    } catch (error) {}
  };

  return (
    <div>
      <Typography
        as="h5"
        variant="h5"
        className="font-inter text-3xl dark:text-white pb-2"
        //   relative after:absolute after:-bottom-1 after:left-0 after:bg-gradient-to-r after:from-[#3fc55d] after:to-[rgba(118,20,20,0)] after:max-w-[305px] after:w-full after:h-1
        placeholder={"Bình luận"}
      >
        Bình luận
      </Typography>

      {comments && comments?.length > 0 ? (
        <Fragment>
          <Card
            shadow={false}
            className="bg-transparent px-1"
            placeholder={"Bình luận"}
          >
            {comments?.map((cmt: any, index: any) => (
              <CardBody
                key={index}
                className={`p-0 m-0 flex gap-2 items-start justify-start py-2 pb-3 ${
                  index < comments?.length - 1 && ""
                }`}
                placeholder={cmt?.author_name}
              >
                <div className="flex items-start justify-between">
                  <Avatar
                    className="md:w-10 md:h-10"
                    src={`https://avatar.iran.liara.run/public/${cmt?.id}`}
                    alt={cmt?.author_name}
                    placeholder={cmt?.author_name}
                  />
                </div>
                <div className="w-full">
                  <div className="border border-gray-300/80 bg-white w-full dark:border-gray-300/10  shadow-sm bg-bgPost dark:bg-bgPost_dark overflow-hidden rounded-md p-2 pt-1">
                    <Typography
                      color="blue-gray"
                      className="dark:text-white pt-0 mt-0"
                      variant="h6"
                      placeholder={cmt?.author_name}
                    >
                      {cmt?.author_name}
                    </Typography>
                    <Typography
                      variant="small"
                      className="font-medium dark:text-white/80 text-black/80"
                      placeholder={cmt?.content?.rendered}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cmt?.content?.rendered,
                        }}
                      ></div>
                    </Typography>
                  </div>
                  <Typography
                    variant="small"
                    color="gray"
                    className="font-medium text-xs dark:text-white/50 !flex gap-1 items-center justify-start mt-1"
                    placeholder={formatDistanceToNow(new Date(cmt?.date), {
                      addSuffix: true,
                      locale: vi,
                    })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16px"
                      viewBox="0 -960 960 960"
                      width="16px"
                      className="fill-timer dark:fill-white/60"
                    >
                      <path d="M520-496v-144q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v159q0 8 3 15.5t9 13.5l132 132q11 11 28 11t28-11q11-11 11-28t-11-28L520-496ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
                    </svg>
                    {formatDistanceToNow(new Date(cmt?.date), {
                      addSuffix: true,
                      locale: vi,
                    })}
                  </Typography>
                </div>
              </CardBody>
            ))}
          </Card>

          <PaginationComponent
            className="mb-5"
            currentPage={pagi?.currentPage}
            pageCount={pagi?.pageCount}
            changeOffset={handleGetCommentByPost}
          />
        </Fragment>
      ) : null}

      <CommentForm
        handleGetCommentByPost={handleGetCommentByPost}
        postId={postId}
      />
    </div>
  );
}

export default CommentComponent;
