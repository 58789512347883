import { Typography } from "@material-tailwind/react";
import postApi from "apis/postApi";
import BreadcrumbComponent from "components/BreadcrumbComponent/BreadcrumbComponent";
import PostItemComponent from "components/PostItemComponent/PostItemComponent";
import PostItemSkeleton from "components/PostItemComponent/PostItemSkeleton";
import PostItemRowComponent from "components/PostItemRowComponent/PostItemRowComponent";
import { setListPostCahce } from "features/postSlice";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { replaceTitleCategory, sliceArrayTo } from "utils/postFormat";

function CategoryPostPage() {
  const { slug } = useParams();

  const [listPost, setListPost] = useState<any>([]);
  const listMenuMain = useSelector((state: any) => state.common.listMenuMain);
  const listPostCache = useSelector((state: any) => state.post.listPostCache);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const { isMobile, isTablet, isMiniDesktop } = useSelector(
    (state: any) => state.layout
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const listGamesRef = useRef<HTMLDivElement>(null);
  const [offsetListGame, setOffsetListGame] = useState<number>(300);

  const [breadcrumbItems, setBreadcrumbItems] = useState<any[]>([
    { title: "Trang chủ", url: "/" },
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const newOffset = containerRef.current?.getBoundingClientRect().top;
        if (newOffset >= 84) {
          setOffsetListGame(newOffset);
        }
      }
    };
    window.document.addEventListener("scroll", handleScroll);
    return () => {
      window.document.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  useEffect(() => {
    if (slug) {
      const category = handleGetCateBySlug(listMenuMain, slug);
      if (category?.name) {
        setTitle(replaceTitleCategory(category?.name));
        setBreadcrumbItems([
          { title: "Trang chủ", url: "/" },
          {
            title: category?.name,
          },
        ]);
      }
      if (
        !listPostCache ||
        !listPostCache[slug] ||
        listPostCache[slug]?.length === 0
      ) {
        handleGetListPostByCate(category?.id);
      } else {
        setLoading(false);
        setListPost(listPostCache[slug]);
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [slug, listPostCache, listMenuMain]);

  const handleGetCateBySlug = (array: any, slug: any) => {
    try {
      const menuCategory = array?.find(
        (item: any) => String(item?.key) === "post"
      )?.listMenu;
      const category = menuCategory?.find(
        (cate: any) => String(cate?.slug) === String(slug)
      );
      return category;
    } catch (error) {
      return {};
    }
  };

  const handleGetListPostByCate = async (id: any) => {
    try {
      if (!slug || !id) return;
      setLoading(true);
      const { data } = await postApi.getListPost({
        categories: id,
      });
      if (data && data?.length > 0) {
        setListPost(data);
        dispatch(
          setListPostCahce({
            ...listPostCache,
            [slug]: data,
          })
        );
      } else {
        setListPost([]);
        // dispatch(
        //   setListPostCahce({
        //     ...listPostCache,
        //     [slug]: [],
        //   })
        // );
      }
    } catch (error) {
      setListPost([]);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {/* {!isMobile ? ( */}
      <div className="playback-page w-full h-full flex flex-col items-center overflow-x-hidden">
        <div className="flex w-full h-full relative justify-center">
          <div
            className={`flex justify-center flex-row md:flex-col-reverse w-full ${
              isTablet ? "p-4" : "p-2"
            } max-w-[1440px]`}
          >
            <div
              className={`h-full min-w-[256px] md:min-w-full md:mt-6 mr-4 md:mr-0 ${
                isMiniDesktop || isTablet
              }`}
            >
              <div
                className={` delay-100 transition-all duration-500`}
                style={{
                  top: offsetListGame,
                  position: isMobile || isTablet ? "unset" : "fixed",
                }}
                ref={listGamesRef}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 min-w-[256px] md:min-w-full md:max-w-full max-w-[256px]">
                  <div className="grid-col-span-1 w-full rounded-lg overflow-hidden">
                    <img
                      loading="eager"
                      src={require("assets/gifs/vnloto.gif")}
                      className="max-w-none w-full h-full object-cover"
                    />
                  </div>
                  <div className="grid-col-span-1 w-full rounded-lg overflow-hidden">
                    <img
                      src={require("assets/gifs/mig8.gif")}
                      className="max-w-none w-full h-full object-cover"
                      alt=""
                    />
                  </div>
                  <div className="grid-col-span-1 w-full rounded-lg overflow-hidden">
                    <img
                      src={require("assets/gifs/lixi.gif")}
                      className="max-w-none w-full h-full object-cover"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="content-page w-full h-full flex justify-center"
              ref={containerRef}
            >
              <div className="main-center-wrap w-full flex flex-col">
                <div>
                  {title && (
                    <div className="mb-6">
                      <Typography
                        as="h1"
                        variant="h2"
                        className="font-inter  dark:text-white pb-1"
                        //   relative after:absolute after:-bottom-1 after:left-0 after:bg-gradient-to-r after:from-[#3fc55d] after:to-[rgba(118,20,20,0)] after:max-w-[305px] after:w-full after:h-1
                        placeholder={title}
                      >
                        {title}
                      </Typography>

                      <div className="w-full">
                        <BreadcrumbComponent items={breadcrumbItems} />
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-3 lg:grid-cols-2 md:!grid-cols-1 gap-4">
                    {!loading && (
                      <Fragment>
                        {sliceArrayTo(listPost, 0, 1) &&
                        sliceArrayTo(listPost, 0, 1)[0] ? (
                          <div className="col-span-3 lg:col-span-2 md:!col-span-1">
                            <PostItemRowComponent
                              title={
                                sliceArrayTo(listPost, 0, 1)[0]?.title
                                  ?.rendered ?? ""
                              }
                              image={
                                sliceArrayTo(listPost, 0, 1)[0]
                                  ?.thumbnail_url ?? ""
                              }
                              desc={
                                sliceArrayTo(listPost, 0, 1)[0]?.excerpt
                                  ?.rendered ?? ""
                              }
                              href={sliceArrayTo(listPost, 0, 1)[0]?.slug ?? ""}
                              date={sliceArrayTo(listPost, 0, 1)[0]?.date ?? ""}
                            />
                          </div>
                        ) : (
                          <Fragment>
                            <div className="col-span-3 lg:col-span-2 md:!col-span-1 text-center dark:text-white text-black/90 ">
                              Chưa có bài viết nào!
                            </div>
                          </Fragment>
                        )}
                      </Fragment>
                    )}

                    {loading ? (
                      <Fragment>
                        {Array.from({ length: 6 }, (_, index) => (
                          <PostItemSkeleton key={index} />
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {sliceArrayTo(listPost, 1, listPost?.length) &&
                        sliceArrayTo(listPost, 1, listPost?.length).length > 0
                          ? sliceArrayTo(listPost, 1, listPost?.length)?.map(
                              (post: any) => (
                                <div key={post?.id} className="col-span-1">
                                  <PostItemComponent
                                    title={post?.title?.rendered}
                                    image={post?.thumbnail_url}
                                    desc={post?.excerpt?.rendered}
                                    href={post?.slug}
                                    date={post?.date}
                                  />
                                </div>
                              )
                            )
                          : null}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <div
          className={classNames(
            "content-mobile w-full h-full flex flex-col items-center relative"
          )}
        ></div>
      )} */}
    </>
  );
}

export default CategoryPostPage;
