import axios from "axios";
import { setShowToastGlobal } from "features/commonSlice";
import store from "../app/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_POST,
  timeout: 60000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isToastShown = false;
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const { data } = error?.response;
      if (data && data?.msg) {
        if (!isToastShown) {
          isToastShown = true;
          store.dispatch(
            setShowToastGlobal({
              show: true,
              type: "error",
              msg: data?.msg ? data?.msg : "Đã xảy ra lỗi khi lấy dữ liệu!",
            })
          );
          setTimeout(() => {
            isToastShown = false;
          }, 3000);
        }
      }
      return Promise.reject(data);
    } catch (error) {
      return;
    }
  }
);

export default axiosInstance;
