import { Fragment, useEffect, useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { listMenuHeader } from "constant";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addListMenuMain } from "features/commonSlice";
import postApi from "apis/postApi";
import { menuHeaderI } from "interfaces/common.interface";
import { replaceTitleCategory } from "utils/postFormat";
import MenuLotteryMegaComponent from "components/MenuLotteryMegaComponent/MenuLotteryMegaComponent";
import { checkIsPageUrl } from "utils/commonFormat";
function MenuMainComponent() {
  const { slug } = useParams();

  const [openMenu, setOpenMenu] = useState({
    child: false,
    father: false,
  });
  const handleHoverMenu = (type: any, value: any) => {
    setOpenMenu({
      ...openMenu,
      [type]: value,
    });
  };
  const listMenuMain = useSelector((state: any) => state.common.listMenuMain);
  const dispatch = useDispatch();
  const { mode } = useSelector((state: any) => state.layout);

  useEffect(() => {
    if (!listMenuMain || listMenuMain?.length === 0) {
      handleGetMenuMain();
    }
  }, []);

  const handleGetMenuMain = async () => {
    try {
      const { data } = await postApi.getListCategories();

      if (data && data?.length > 0) {
        const updatedListMenuHeader = listMenuHeader.map((menu: any) => {
          if (menu?.key === "post") {
            // Thêm dữ liệu vào listMenu của mục "post"
            return {
              ...menu,
              listMenu: data,
            };
          }
          return menu;
        });
        dispatch(addListMenuMain(updatedListMenuHeader));
      } else {
        dispatch(addListMenuMain(listMenuHeader));
      }
    } catch (error) {
      dispatch(addListMenuMain(listMenuHeader));
    }
  };

  return (
    <div className="flex gap-8 justify-start items-center">
      {/* <MenuLottery className="h-full max-h-[60vh]" /> */}
      {listMenuMain?.map((menu: menuHeaderI) => {
        if (menu?.listMenu && menu?.listMenu?.length > 0) {
          return (
            <Menu
              key={menu?.url}
              placement="top-start"
              animate={{
                mount: { y: 0 },
                unmount: { y: 25 },
              }}
              open={openMenu?.father}
              handler={(value: any) => handleHoverMenu("father", value)}
              allowHover
              offset={15}
            >
              <MenuHandler>
                <Link
                  className={` group text-md font-semibold hover:text-primary flex justify-start items-center gap-1 ${
                    checkIsPageUrl(menu?.url, "post")
                      ? "text-primary"
                      : "text-white"
                  }`}
                  to={menu?.url}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: menu?.icon,
                    }}
                    className={`group-hover:!fill-primary group-hover:!text-primary ${
                      checkIsPageUrl(menu?.url, "post")
                        ? "!fill-primary !text-primary"
                        : "!fill-white !text-white"
                    }`}
                  ></div>
                  {menu?.title}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="13px"
                    viewBox="0 -960 960 960"
                    width="13px"
                    className={`transition-transform ${
                      openMenu?.father ? "-rotate-90" : "rotate-90"
                    } ${
                      checkIsPageUrl(menu?.url, "post")
                        ? "!fill-primary"
                        : "!fill-white"
                    }`}
                  >
                    <path d="M579-480 285-774q-15-15-14.5-35.5T286-845q15-15 35.5-15t35.5 15l307 308q12 12 18 27t6 30q0 15-6 30t-18 27L356-115q-15 15-35 14.5T286-116q-15-15-15-35.5t15-35.5l293-293Z" />
                  </svg>
                </Link>
              </MenuHandler>
              <MenuList
                placeholder={menu?.title}
                className={`dark:bg-bgPrimary dark:border-borderPrimary`}
              >
                {menu?.listMenu?.map((child: any) => (
                  <Link
                    key={child?.slug}
                    to={`../danh-muc/${child?.slug}`}
                    className="!outline-0 !border-none hover:text-primary"
                  >
                    <MenuItem
                      className={`${
                        checkIsPageUrl(child?.slug, "menu")
                          ? "bg-blue-gray-50 bg-opacity-80 text-blue-gray-900  dark:bg-bgSecondary"
                          : ""
                      } dark:hover:bg-bgSecondary dark:focus:bg-bgSecondary dark:active:bg-bgSecondary dark:!text-white dark:!outline-0 dark:!border-none`}
                      key={child?.slug}
                      placeholder={replaceTitleCategory(child?.name)}
                    >
                      {replaceTitleCategory(child?.name)}
                    </MenuItem>
                  </Link>
                ))}

                {/* <Menu
                  placement="right-start"
                  open={openMenu?.child}
                  handler={(value: any) => handleHoverMenu("child", value)}
                  allowHover
                  offset={15}
                >
                  <MenuHandler className="flex items-center justify-between">
                    <MenuItem placeholder={""}>
                      Nested Item
                      <img
                        src={icons.arrowDown}
                        className={`h-3.5 w-3.5 transition-transform rotate-0 ${
                          openMenu?.child ? "-rotate-90" : "rotate-180"
                        }`}
                      />
                    </MenuItem>
                  </MenuHandler>
                  <MenuList placeholder={""}>
                    <MenuItem placeholder={""}>
                      <Link to={""}>Hello</Link>
                    </MenuItem>
                  </MenuList>
                </Menu> */}
              </MenuList>
            </Menu>
          );
        } else {
          return (
            <Fragment key={menu?.url}>
              {menu.key === "kqxs" ? (
                <MenuLotteryMegaComponent menu={menu} />
              ) : (
                <Menu>
                  <Link
                    className={`group text-md font-semibold hover:text-primary flex justify-start items-center gap-1 ${
                      checkIsPageUrl(menu?.url) ? "text-primary" : "text-white"
                    }`}
                    to={menu?.url}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: menu?.icon,
                      }}
                      className={`group-hover:!fill-primary group-hover:!text-primary ${
                        checkIsPageUrl(menu?.url)
                          ? "!fill-primary !text-primary"
                          : "!fill-white !text-white"
                      }`}
                    ></div>
                    {menu?.title}
                  </Link>
                </Menu>
              )}
            </Fragment>
          );
        }
      })}
    </div>
  );
}
export default MenuMainComponent;
