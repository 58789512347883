import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  convertNameLotteryToParam,
  formatGetNameLottery,
  formatStringWinnumberToResultData,
  handleConvertDauDuoi,
  handleFormatDauDuoi,
  handleFormatDuoiDau,
  handleGroupDauDuoi,
} from "utils/lotteryFormat";

function TableDauDuoiComponent(props: any) {
  const {
    data,
    changedPrizesInfo,
    region,
    listName,
    regionLotteryActive,
    getDauDuoi,
    numberSelected,
    numberHovered,
    getDauDuoiSelected,
    getDauDuoiHover,
    getListDauDuoiSelected,
  } = props;
  const [dauDuoiData, setDauDuoiData] = useState<any[]>([]);
  const [duoiDauData, setDuoiDauData] = useState<any[]>([]);
  const [dataSelected, setDataSelected] = useState<any>([]);
  const [listDataSelected, setListDataSelected] = useState<any>([]);
  const [listDataHover, setListDataHover] = useState<any>([]);
  const [dataTableMain, setDataTableMain] = useState([]);
  const { listGames } = useSelector((state: any) => state.lottery);
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);

  useEffect(() => {
    setDataTableMain(
      handleGroupDauDuoi(
        handleFormatDuoiDau(handleFormatDuoiDau(duoiDauData)),
        handleFormatDauDuoi(handleFormatDauDuoi(dauDuoiData, "LO2SO"), "LO2SO")
      )
    );
  }, [duoiDauData, dauDuoiData]);

  useEffect(() => {
    if (numberSelected !== null) {
      const dataFind = getElementsWithKey(dauDuoiData, numberSelected);
      setListDataSelected(dataFind);
      getListDauDuoiSelected(dataFind);
    }
    if (numberHovered) {
      const dataFind = getElementsWithKey(dauDuoiData, numberHovered);
      setListDataHover(dataFind);
      getDauDuoiHover(dataFind);
    } else {
      setListDataHover([]);
      getDauDuoiHover(null);
    }

    return () => {
      setListDataSelected([]);
      getListDauDuoiSelected(null);
      setListDataHover([]);
      getDauDuoiHover(null);
    };
  }, [numberSelected, numberHovered]);

  useEffect(() => {
    const filteredData =
      data?.length > 0 &&
      data?.map((component: { gameid: any }) =>
        filterChangedPrizes(component, changedPrizesInfo)
      );
    const convertedData = convertToWinNumbers(filteredData);
    const handleConvertDauDuoiData = handleConvertDauDuoi(convertedData);
    setDauDuoiData(handleConvertDauDuoiData);
    const handleConvertDuoiDauData = handleConvertDuoiDau(convertedData);
    setDuoiDauData(handleConvertDuoiDauData);
  }, [data, changedPrizesInfo]);

  function filterChangedPrizes(component: any, changes: any[]) {
    const updatedComponent: any = { ...component };
    const changedPrize = changes.find(
      (info) => info.gameid === component.gameid
    );
    if (changedPrize) {
      Object.entries(updatedComponent)?.forEach(([category, value]) => {
        if (category !== "gameid" && value) {
          const values = value.toString().split(",");
          const changedIndexes = changedPrize[category];
          if (changedIndexes && changedIndexes.length > 0) {
            updatedComponent[category] =
              values?.length > 0 &&
              values
                .map((num, index) =>
                  changedIndexes?.includes(index) ? "" : num
                )
                .join(",");
          }
        }
      });
    }

    return updatedComponent;
  }

  function createWinNumber(data: any) {
    let winnumber = "";

    // Thêm các giải vào chuỗi winnumber
    winnumber += data?.giaidb + ",";
    winnumber += data?.giainhat + ",";
    winnumber += data?.giainhi + ",";
    winnumber += data?.giaiba + ",";
    winnumber += data?.giaitu + ",";
    winnumber += data?.giainam + ",";
    winnumber += data?.giaisau + ",";
    winnumber += data?.giaibay + ",";
    winnumber += data?.giaitam + ",";
    // Loại bỏ dấu phẩy cuối cùng
    winnumber = winnumber.slice(0, -1);
    return winnumber;
  }

  function convertToWinNumbers(dataArray: any[]) {
    // Sử dụng map để chuyển đổi mỗi đối tượng trong mảng
    const convertedData =
      dataArray?.length > 0 &&
      dataArray?.map((item: { gameid: any }) => {
        return {
          gameid: item?.gameid,
          winnumber: createWinNumber(item),
        };
      });

    return convertedData;
  }

  //Đuôi đầu
  const handleConvertDuoiDau = (arr: any) => {
    const listDuoiDau =
      arr?.length > 0 &&
      arr?.map((item: any) => {
        return handleFormatDuoiDau(
          formatStringWinnumberToResultData(item.gameid, item.winnumber)
        );
      });
    const duoidau: any = [];
    listDuoiDau?.length > 0 &&
      listDuoiDau[0]?.forEach((item: any, index: number) => {
        const newObj: any = { key: item.key, dau: [] };
        listDuoiDau?.forEach((arr: any) => {
          newObj.dau.push(arr[index]?.value);
        });
        duoidau.push(newObj);
      });
    return duoidau;
  };

  const handleSelectDataHover = (
    e: any,
    data: any,
    indexParent: number,
    type: string,
    indexChild: number
  ) => {
    e.preventDefault();

    const dauduoiSelect = {
      key: data?.key,
      dau: data?.dau,
      duoi: data?.duoi,
      type: type,
      index: indexParent,
      indexChild: indexChild,
    };
    let listDataSelect = dataSelected.filter(
      (item: any) => item.index !== indexParent
    );
    const existsInDataSelected = dataSelected.some(
      (item: any) =>
        item.index === indexParent &&
        item.key === data?.key &&
        item.type === type &&
        item.dau === data?.dau &&
        item.duoi === data?.duoi &&
        item.indexChild === indexChild
    );

    const existsInListDataSelect = listDataSelected?.some(
      (itemList: any) =>
        itemList.index === indexParent && itemList.key === data?.key
    );

    if (existsInListDataSelect) {
      const filterListDataSelected = listDataSelected?.filter(
        (itemList: any) =>
          itemList.index !== indexParent && itemList.key === data?.key
      );
      setListDataSelected(filterListDataSelected);
      getListDauDuoiSelected(filterListDataSelected);
    } else {
      if (!existsInDataSelected) {
        listDataSelect = [...listDataSelect, dauduoiSelect];
      } else {
        listDataSelect = dataSelected?.filter(
          (item: any) =>
            item.index !== indexParent &&
            item.key !== data?.key &&
            item.indexChild !== indexChild
        );
      }
      setDataSelected(listDataSelect);
      getDauDuoiSelected(listDataSelect);
    }
  };

  const getElementsWithKey = (data: any, key: string) => {
    return data?.flatMap((subArray: any, index: number) =>
      subArray
        .filter((item: any) => item.key.toString() === key)
        .map((mapItem: any) => ({
          ...mapItem,
          index: index,
          type: "both",
        }))
    );
  };

  const handleMouseEnter = (
    e: any,
    data: any,
    indexParent: number,
    type: string
  ) => {
    e.preventDefault();
    if (isMobile) {
      return;
    }
    getDauDuoi(data, indexParent, type);
  };

  const handleMouseLeave = (e: any) => {
    if (isMobile) {
      return;
    }
    getDauDuoi("");
  };

  return (
    <div className="flex flex-col w-full">
      <div
        className={classNames(
          "bg-surface-tertiary dark:bg-bgPrimary flex items-center text-base border-b border-borderLightPrimary dark:border-borderPrimary w-full",
          { "!hidden": region === "mb" }
        )}
      >
        {data?.length > 0 &&
          data?.map((item: any, index: number) => (
            <Fragment key={index}>
              <span
                className={` min-h-9
                 ${
                   index !== 0 && "border-l"
                 } text-base md:text-sm w-full border-borderLightPrimary dark:border-borderPrimary flex items-center justify-center `}
              >
                <Link
                  to={`./ket-qua/${convertNameLotteryToParam(
                    formatGetNameLottery(item?.gameid, listGames)
                  )}`}
                  className="hover:underline font-semibold text-primary"
                >
                  {formatGetNameLottery(item?.gameid, listGames)}
                </Link>
              </span>
            </Fragment>
          ))}
      </div>
      <div className="flex items-center">
        {dauDuoiData &&
          dauDuoiData?.length > 0 &&
          dauDuoiData?.map((dauduoi: any, index: number) => (
            <div
              className={classNames("w-full flex flex-col", {
                "border-l border-borderLightPrimary dark:border-borderPrimary":
                  index > 0,
              })}
              key={index}
            >
              <div
                className={classNames(
                  "wrap-prize text-primary bg-surface-tertiary dark:bg-bgPrimary text-sm font-semibold flex items-center",
                  {
                    "!text-xs": isMobile,
                    "h-6": String(region) === "mb" && isMobile,
                    "h-12": String(region) === "mb" && !isMobile,
                    "h-7": String(region) !== "mb",
                  }
                )}
              >
                <div className="title-prize h-full flex items-center justify-center w-2/5">
                  <span className="text-center">Chục</span>
                </div>
                <div className="title-prize h-full flex items-center justify-center w-1/5 border-x border-borderLightPrimary dark:border-borderPrimary">
                  <span className="text-center">Số</span>
                </div>
                <div className="number-prize h-full flex items-center justify-center w-2/5">
                  <span className="text-center">Đơn vị</span>
                </div>
              </div>
              <div className={classNames("h-full flex-1", {})}>
                {dauduoi?.length > 0 &&
                  dauduoi?.map((item: any, indexChild: number) => {
                    const isSelectedDau = dataSelected?.some(
                      (selectedItem: any) =>
                        selectedItem.index === index &&
                        selectedItem.type === "dau" &&
                        selectedItem.dau === item.dau &&
                        selectedItem.indexChild === indexChild
                    );

                    const isSelectedDuoi = dataSelected?.some(
                      (selectedItem: any) =>
                        selectedItem.index === index &&
                        selectedItem.type === "duoi" &&
                        selectedItem.duoi === item.duoi &&
                        selectedItem.indexChild === indexChild
                    );
                    const isSelectedBoth =
                      dataSelected?.some(
                        (selectedItem: any) =>
                          selectedItem.key === item.key &&
                          selectedItem.index === index &&
                          selectedItem.type === "both" &&
                          selectedItem.indexChild === indexChild
                      ) ||
                      listDataSelected?.some(
                        (listSelectedItem: any) =>
                          listSelectedItem.index === index &&
                          listSelectedItem.key === item.key &&
                          listSelectedItem.type === "both"
                      ) ||
                      listDataHover?.some(
                        (listHoveredItem: any) =>
                          listHoveredItem.index === index &&
                          listHoveredItem.key === item.key &&
                          listHoveredItem.type === "both"
                      );

                    return (
                      <div
                        className={classNames(
                          "wrap-prize flex items-center border-t border-borderLightPrimary dark:border-borderPrimary text-sm leading-normal",
                          {
                            "!text-[11px]": isMobile,
                            "h-6": String(region) === "mb" && isMobile,
                            "h-9": String(region) === "mb" && !isMobile,
                            "h-7": String(region) !== "mb",
                          }
                        )}
                        key={indexChild}
                      >
                        <div
                          className={classNames(
                            "number-prize h-full leading-1 flex items-center justify-center w-2/5 cursor-pointer",
                            {
                              "!bg-gray-200 dark:!bg-gray-800":
                                isSelectedDau || isSelectedBoth,
                              "hover:bg-gray-200 dark:hover:bg-gray-800":
                                !isMobile,
                            }
                          )}
                          onClick={(e) =>
                            handleSelectDataHover(
                              e,
                              item,
                              index,
                              "dau",
                              indexChild
                            )
                          }
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, item, index, "dau")
                          }
                          onMouseLeave={handleMouseLeave}
                        >
                          <span
                            className={`text-black dark:text-white text-center text-wrap break-words w-[95%] font-semibold`}
                          >
                            {item.dau}
                          </span>
                        </div>
                        <div
                          className={classNames(
                            "title-prize h-full flex items-center border-x border-borderLightPrimary dark:border-borderPrimary justify-center w-1/5 cursor-pointer",
                            {
                              "!bg-gray-200 dark:!bg-gray-800": isSelectedBoth,
                              "hover:bg-gray-200 dark:hover:bg-gray-800 group":
                                !isMobile,
                              "leading-normal": isMobile,
                            }
                          )}
                          onClick={(e) =>
                            handleSelectDataHover(
                              e,
                              item,
                              index,
                              "both",
                              indexChild
                            )
                          }
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, item, index, "both")
                          }
                          onMouseLeave={handleMouseLeave}
                        >
                          <p
                            className={`${
                              isMobile
                                ? "w-4 h-4"
                                : "w-5 h-5 group-hover:bg-primary group-hover:text-white"
                            } rounded-full pt-0.5 ${
                              isSelectedBoth
                                ? "bg-primary text-white"
                                : "bg-surface-tertiary dark:bg-bgSecondary text-text-secondary dark:text-text-secondary"
                            } flex items-center justify-center text-center font-semibold`}
                          >
                            {item.key}
                          </p>
                        </div>
                        <div
                          className={classNames(
                            "number-prize h-full leading-1 flex items-center justify-center w-2/5 cursor-pointer",
                            {
                              "!bg-gray-200 dark:!bg-gray-800":
                                isSelectedDuoi || isSelectedBoth,
                              "hover:bg-gray-200 dark:hover:bg-gray-800":
                                !isMobile,
                              "leading-normal": isMobile,
                            }
                          )}
                          onClick={(e) =>
                            handleSelectDataHover(
                              e,
                              item,
                              index,
                              "duoi",
                              indexChild
                            )
                          }
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, item, index, "duoi")
                          }
                          onMouseLeave={handleMouseLeave}
                        >
                          <span
                            className={`text-black dark:text-white  text-center text-wrap break-words w-[95%] font-semibold`}
                          >
                            {item.duoi}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default TableDauDuoiComponent;
