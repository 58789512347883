const replaceTitleCategory = (title: string) => {
  try {
    if (title?.includes("–")) {
      const titleReplace = title?.split("–");
      return titleReplace[0];
    }
    return title;
  } catch (error) {
    return "";
  }
};

const sliceArrayTo = (array: any[], start: number, count: number) => {
  try {
    if (!Array.isArray(array)) {
      throw new Error("Invalid input: 'array' must be an array.");
    }
    if (typeof start !== "number" || typeof count !== "number") {
      throw new Error("Invalid input: 'start' and 'count' must be numbers.");
    }

    return array.slice(start, start + count);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export { replaceTitleCategory, sliceArrayTo };
