import axios from "axios";
import { addRefreshToken, checkAuth, login, logout } from "features/authSlice";
import { setShowToastGlobal } from "features/commonSlice";
import { addBalanceUser, addUserInfo } from "features/userSlice";
import store from "../app/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 60000,
});

axiosInstance.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE";

axiosInstance.interceptors.request.use(
  (config) => {
    const { token } = store.getState().auth;
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshingToken = false;
let isToastShown = false;
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { data } = error?.response;
    if (data && data?.msg) {
      const originalRequest = error.config;
      const searchErrorCode = [40101, 40102, 40103];
      const searchErrorCode2 = [40301];
      const searchErrorCodeBlock = [1001];

      const error_code = error?.response?.data?.error_code;

      const tokenExpired = searchErrorCode?.some(
        (searchText) => error_code === searchText
      );
      const otherLogin = searchErrorCode2?.some(
        (searchText) => error_code === searchText
      );
      const blockUser = searchErrorCodeBlock?.some(
        (searchText) => error_code === searchText
      );
      // Xử lý tokenExpired
      if (tokenExpired) {
        try {
          if (!isRefreshingToken) {
            // Kiểm tra xem đã gọi yêu cầu refresh token chưa
            isRefreshingToken = true; // Đánh dấu rằng yêu cầu refresh token đang được gọi
            const { refreshToken } = store.getState().auth;
            // Kiểm tra refreshToken

            if (refreshToken === null || refreshToken === "null") {
              if (!isToastShown) {
                store.dispatch(
                  setShowToastGlobal({
                    show: true,
                    type: "error",
                    msg: "Đã xảy ra lỗi, vui lòng đăng nhập lại!",
                  })
                );
                isToastShown = true;
                setTimeout(() => {
                  isToastShown = false;
                }, 3000);
              }
              handleLogoutOut();
              return Promise.reject(data);
            }
            try {
              const refreshTokenResponse = await axios.get(
                `${process.env.REACT_APP_BASE_API}/api/v1/user/refeshtoken`,
                {
                  headers: {
                    Authorization: `Bearer ${refreshToken}`,
                  },
                }
              );
              const newToken = refreshTokenResponse.data.data.token;
              store.dispatch(login(newToken));
              store.dispatch(
                addRefreshToken(refreshTokenResponse.data.data.refeshtoken)
              );

              originalRequest.headers.Authorization = `Bearer ${newToken}`;
            } catch (error) {
              // toast.error('Đã xảy ra lỗi, vui lòng đăng nhập lại');
              // alert("erorr");
              handleLogoutOut();
            }
            return axios(originalRequest);
          }
        } catch (error: any) {
          handleLogoutOut();
          if (error.msg) {
            store.dispatch(
              setShowToastGlobal({
                show: true,
                type: "error",
                msg: error?.msg,
              })
            );
          }
          return Promise.reject(error);
        } finally {
          setTimeout(() => {
            isRefreshingToken = false;
          }, 3000);
        }
      } else if (otherLogin || blockUser) {
        if (!isToastShown) {
          isToastShown = true;
          // if (data?.msg) toast.error(data?.msg);
          handleLogoutOut();
          store.dispatch(
            setShowToastGlobal({
              show: true,
              type: "error",
              msg: data?.msg
                ? data?.msg
                : "Đã xảy ra lỗi vui lòng đăng nhập lại!",
            })
          );

          setTimeout(() => {
            isToastShown = false;
          }, 3000);
        }
      } else {
        if (!isToastShown) {
          isToastShown = true;
          store.dispatch(
            setShowToastGlobal({
              show: true,
              type: "error",
              msg: data?.msg
                ? data?.msg
                : "Đã xảy ra lỗi vui lòng đăng nhập lại!",
            })
          );
          setTimeout(() => {
            isToastShown = false;
          }, 3000);
        }
      }
    }
    return Promise.reject(data);
  }
);

const handleLogoutOut = () => {
  store.dispatch(logout());
  store.dispatch(checkAuth(false));
  store.dispatch(addUserInfo(null));
  store.dispatch(addBalanceUser(0));
  store.dispatch(addRefreshToken(null));
};

export default axiosInstance;
