import { Button, Input, Textarea, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { CommentFormI } from "interfaces/post.interface";
import { toast } from "react-toastify";
import postApi from "apis/postApi";

function CommentForm({ postId, handleGetCommentByPost }: CommentFormI) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    content: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    content: "",
  });

  useEffect(() => {
    return () => {
      resetData();
    };
  }, [postId]);

  const handleChange = (field: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const resetData = () => {
    setFormData({
      name: "",
      email: "",
      content: "",
    });
    setErrors({
      name: "",
      email: "",
      content: "",
    });
  };

  const validateForm = () => {
    try {
      const newErrors: { name: string; email: string; content: string } = {
        name: "",
        email: "",
        content: "",
      };

      if (!formData.name.trim())
        newErrors.name = "Họ và tên không được để trống.";
      if (formData.email.trim() && !/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = "Email không hợp lệ.";
      }
      if (!formData.content.trim())
        newErrors.content = "Nội dung bình luận không được để trống.";

      setErrors(newErrors);
      return !Object.values(newErrors).some((error) => error !== "");
    } catch (error) {}
  };

  const handleSubmitComment = async () => {
    try {
      if (validateForm()) {
        const payload = {
          post: postId,
          author_name: formData?.name,
          author_email: formData?.email,
          content: formData?.content,
        };
        handleGetCommentByPost();
        setLoading(true);
        const { data } = await postApi?.postCommentByPost(payload);
        if (data) {
          handleGetCommentByPost();
          toast.success("Bình luận đã được gửi thành công!");
          resetData();
        }
      }
    } catch (error: any) {
      toast.error(error?.message || "Đã xảy ra lỗi!");
      console.error("Đã xảy ra lỗi:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="w-full mt-2 pt-6 bg-white shadow dark:shadow-white/10 dark:bg-bgPost_dark p-3 rounded-lg">
        <div className="flex w-full flex-col gap-2">
          <div className="w-full grid grid-cols-2 gap-2">
            {/* Họ và tên */}
            <label htmlFor="Họ và tên" className="col-span-1" >
              <Typography
                variant="small"
                color="blue-gray"
                className="block font-medium mb-1 text-black/80 dark:text-white/80"
                placeholder={undefined}
              >
                Họ và tên*
              </Typography>

              <Input
                crossOrigin={"Họ và tên"}
                type="text"
                id="Họ và tên"
                placeholder="Họ và tên"
                value={formData.name}
                onChange={(e) => handleChange("name", e.target.value)}
                className="!border-gray-300/80  dark:!border-gray-300/10 focus:!border-blue-gray-200 placeholder:opacity-100 focus:!border-t-blue-gray-200 dark:focus:!border-gray-300/40  dark:focus:!border-t-gray-300/40 
              focus:ring-white dark:focus:ring-black/10"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{ className: "w-full !min-w-[120px]" }}
              />

              {errors.name && (
                <Typography
                  variant="small"
                  color="red"
                  className="mt-1 text-sm text-red-500 dark:text-red-400"
                  placeholder={undefined}
                >
                  {errors.name}
                </Typography>
              )}
            </label>

            {/* Email */}
            <label className="col-span-1" htmlFor="email">
              <Typography
                variant="small"
                color="blue-gray"
                className="block font-medium mb-1 text-black/80 dark:text-white/80"
                placeholder={undefined}
              >
                Email
              </Typography>
              <Input
                crossOrigin={"Email"}
                type="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => handleChange("email", e.target.value)}
                className="!border-gray-300/80  dark:!border-gray-300/10 focus:!border-blue-gray-200 placeholder:opacity-100 focus:!border-t-blue-gray-200 dark:focus:!border-gray-300/40  dark:focus:!border-t-gray-300/40 
              focus:ring-white dark:focus:ring-black/10"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{ className: "w-full !min-w-[120px]" }}
              />
              {errors.email && (
                <Typography
                  variant="small"
                  color="red"
                  className="mt-1 text-sm text-red-500 dark:text-red-400"
                  placeholder={undefined}
                >
                  {errors.email}
                </Typography>
              )}
            </label>
          </div>

          {/* Nội dung bình luận */}
          <div className="w-full">
            <label htmlFor="content">
              <Typography
                variant="small"
                color="blue-gray"
                className="block font-medium mb-1 text-black/80 dark:text-white/80"
                placeholder={undefined}
              >
                *Nội dung bình luận*
              </Typography>

              <Textarea
                id="content"
                value={formData.content}
                onChange={(e) => handleChange("content", e.target.value)}
                className="!border-gray-300/80  dark:!border-gray-300/10 focus:!border-blue-gray-200 placeholder:opacity-100 focus:!border-t-blue-gray-200 dark:focus:!border-gray-300/40  dark:focus:!border-t-gray-300/40 
              focus:ring-white dark:focus:ring-black/10"
                label="*Nội dung bình luận"
                placeholder="Nội dung bình luận..."
                labelProps={{
                  className: "hidden",
                }}
              />
              {errors.content && (
                <Typography
                  variant="small"
                  color="red"
                  className="mt-1 text-sm text-red-500 dark:text-red-400"
                  placeholder={undefined}
                >
                  {errors.content}
                </Typography>
              )}
            </label>
          </div>

          {/* Button */}
          <div className="w-full pb-1">
            <Button
              className="mr-auto float-end"
              size="md"
              color="green"
              onClick={handleSubmitComment}
              placeholder={undefined}
              loading={loading}
            >
              Gửi
            </Button>
          </div>
        </div>
      </div>
  );
}

export default CommentForm;
