import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const PreviousIcon = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00002 0.705098C6.61002 0.315098 5.98002 0.315098 5.59002 0.705098L1.00002 5.2951C0.61002 5.6851 0.61002 6.3151 1.00002 6.7051L5.59002 11.2951C5.98002 11.6851 6.61002 11.6851 7.00002 11.2951C7.39002 10.9051 7.39002 10.2751 7.00002 9.8851L3.12002 5.9951L7.00002 2.1151C7.39002 1.7251 7.38002 1.0851 7.00002 0.705098Z"
      fill="currentColor"
    />
  </svg>
);

const NextIcon = () => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 8 12"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1.00002.710754c-.39.389996-.39 1.019996 0 1.409996l3.88 3.88-3.88 3.88c-.39.39005-.39 1.02005 0 1.41005s1.02.39 1.41 0l4.59-4.59005c.39-.39.39-1.02 0-1.41l-4.59-4.589996c-.38-.38-1.02-.38-1.41.01z"
      fill="currentColor"
    />
  </svg>
);

function PaginationComponent(props: any) {
  const { pageCount, currentPage, changeOffset, className } = props;
  const [pageCurrent, setPageCurrent] = useState<number>(1);

  useEffect(() => {
    setPageCurrent(currentPage);
  }, [currentPage]);

  const handleChangePagination = (page: number) => {
    setPageCurrent(page);
    changeOffset(page);
  };

  return (
    <div
      className={`${className} w-full flex items-center justify-end md:flex-col  gap-2`}
    >
      <ReactPaginate
        pageCount={pageCount}
        forcePage={pageCurrent}
        onPageChange={(e: any) => {
          handleChangePagination(e.selected);
        }}
        pageRangeDisplayed={3}
        nextLabel={<NextIcon />}
        previousLabel={<PreviousIcon />}
        containerClassName="flex items-center justify-center dark:!text-white/30 dark:hover:!text-white text-black/70 "
        previousClassName="mr-1 paginate dark:!text-white/30 dark:hover:!text-white text-black/70 border border-black/10 dark:border-white/10"
        nextClassName="ml-1 paginate dark:!text-white/30 dark:hover:!text-white text-black/70 border border-black/10 dark:border-white/10"
        pageLinkClassName="mx-1 paginate dark:!text-white/30 dark:hover:!text-white text-black/70 flex items-center justify-center border border-black/10 dark:border-white/10"
        breakClassName="dark:!text-white/30 dark:hover:!text-white text-black/70 hover:text-black mx-2"
        activeLinkClassName="paginate-active"
      />
      {/* <div className="flex gap-2">
     <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleEnterKeyPress}
        className="px-1 ml-1 h-6 rounded py-2 text-primary bg-transparent text-sm outline-none focus:border-success-500 placeholder:text-white/30 w-16 border border-black/30 dark:border-white/30 max-h-6"
      />
      <button
        className="btn-primary h-6 flex items-center justify-center rounded-md px-6 ml-1 text-[13px] font-semibold"
        type="button"
        onClick={handleSubmitOffset}
      >
        Tìm
      </button>
     </div> */}
    </div>
  );
}

export default PaginationComponent;
