import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  BreadcrumbItemI,
  IBreadcrumbComponent,
} from "../../interfaces/common.interface";
import { useDispatch, useSelector } from "react-redux";
import { setConfigSeo } from "features/seoSlice";
import { stripHtml } from "utils/commonFormat";

const BreadcrumbComponent: React.FC<IBreadcrumbComponent> = ({ items }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: items.map((item: BreadcrumbItemI, index: number) => ({
      "@type": "ListItem",
      position: index + 1,
      name: item?.title,
      item: item?.url,
    })),
  };

  const configSeo = useSelector((state: any) => state?.seo?.configSeo);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setConfigSeo({
        ...configSeo,
        title: stripHtml(items[items?.length - 1]?.title || ""),
      })
    );
  }, [items]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>
      </Helmet>

      <nav aria-label="Breadcrumb">
        <ol className="flex items-start whitespace-nowrap truncate">
          {items.map((item, index) => (
            <li key={index} className="inline-flex items-center">
              {index < items.length - 1 ? (
                <>
                  <Link
                    className="flex items-center text-sm text-textTertiary 
                    hover:text-primary focus:text-primary text-black/90 dark:text-white/90 focus:outline-none "
                    to={item?.url || ""}
                  >
                    {stripHtml(item?.title)}
                  </Link>
                  <svg
                    className="shrink-0 mx-1 size-4 text-gray-400 dark:text-neutral-600"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6"></path>
                  </svg>
                </>
              ) : (
                <span
                  className="inline-flex items-center text-sm text-black/70 dark:text-white/70"
                  aria-current="page"
                >
                  {stripHtml(item?.title)}
                </span>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};

export default BreadcrumbComponent;
