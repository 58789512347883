import SkeletonLoading from "components/SkeletonLoading/SkeletonLoading";
import { addListLotteryToday } from "features/lotterySlice";
import useToast from "hooks/useToast";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { icons } from "utils/icons";
import lotteryApi from "../../apis/lotteryApi";
import {
  convertNameLotteryToParam,
  formatGetNameLottery,
} from "../../utils/lotteryFormat";
import LotteryTodayItem from "./LotteryTodayItem";

interface ILottery {
  id: number;
  gameid: number;
  basetime: string;
  starttime: string;
  endtime: string;
  sysopentime: string;
  opentime: string;
  winnumber: string | null;
  desc?: string;
  type?: number;
}

export default function LotteryTodaySection() {
  const navigator = useNavigate();
  const toastify = useToast();
  const dispatch = useDispatch();

  const sliderRef = useRef<any>(null);

  const { listGames,listLotteryToday } = useSelector((state: any) => state.lottery);
  const { isMobile } = useSelector((state: any) => state.layout);
  const { isAuth } = useSelector((state: any) => state.auth);

  const [enableNav, setEnableNav] = useState<any>({
    start: true,
    end: false,
  });
  const [listLottery, setListLottery] = useState<ILottery[]>([]);

  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(false);

  useEffect(() => {
    if (listGames.length > 0 ) {
      getLotteryToDay("first");
    }
  }, [listGames]);

  useEffect(() => {
    let timeoutid: NodeJS.Timeout;
    if (apiCalled) {
      timeoutid = setTimeout(() => {
        getLotteryToDay("callback");
        setApiCalled(false);
      }, 10000);
      setTimeoutId(timeoutid);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [apiCalled]);

  const handleGetReload = (status: boolean) => {
    if (status === true) {
      setApiCalled(true);
    } else {
      setApiCalled(false);
    }
  };

  // Lấy danh sách xs hôm nay
  // Thời gian mở cược : starttime
  // Thời gian đóng cược : endtime
  // Thời gian quay có kết quả : opentime
  const getLotteryToDay = async (type: string) => {
    if (type !== "callback") {
      setLoadingSkeleton(true);
    }
    const today = new Date();
    try {
      const { data } = await lotteryApi.getListSchedulesCurrent({
        date: moment(today).format("YYYY-MM-DD"),
      });
      if (data.error_code === 0) {
        const currentData = data.data.curSchedules;
        const previousData = data.data.preSchedules;
        const listDatas = [...currentData, ...previousData];
        // Kết hợp dữ liệu từ hai API
        const results = listDatas
          .map((item: any) => ({
            id: item?.id,
            gameid: item?.gameid,
            basetime: item?.basetime,
            starttime: item?.starttime,
            endtime: item?.endtime,
            sysopentime: item?.sysopentime,
            opentime: item?.opentime,
            imageurl: listGames?.find((game: any) => game.id === item.gameid)
              ?.bgname,
            winnumber: item?.winnumber ? item?.winnumber?.split(",")[0] : null,
            desc: listGames?.find((game: any) => game.id === item.gameid)?.desc,
            type: item?.type,
          }))
          .sort((a: any, b: any) => b.gameid - a.gameid);
        const listLotteryToday = results.map((lottery: any) => ({
          gameid: lottery.gameid,
          opentime: lottery.opentime,
        }));
        if (type !== "callback") {
          dispatch(addListLotteryToday(listLotteryToday));
        }
        setListLottery(results);
      } else {
        console.error("Failed to fetch data from one or both APIs");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setApiCalled(false);
      setLoadingSkeleton(false);
    }
  };

  const goGame = (namegame: string) => {
    // if (!isAuth) {
    //   toastify("Vui lòng đăng nhập!", "warning");
    //   return;
    // }
    navigator(`/ket-qua/${convertNameLotteryToParam(namegame)}`);
  };

  // Handle slide nav
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);
  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  // Handle slide nav end.

  return (
    <div className="w-full max-w-[1440px]">
      <div className="flexBetween">
        <h2
          className={`font-bold ${
            isMobile ? "text-base" : "text-2xl"
          } sm:text-heading text-white text-left`}
        >
          Xổ số hôm nay
        </h2>
      </div>

      <div className="mt-3 w-full flex items-center h-[116px]">
        {!isMobile && (
          <button
            className="min-w-9 h-9 p-2 bg-secondary/10 hover:bg-secondary/20 rounded-full flex items-center justify-center text-primary mr-2.5"
            type="button"
            onClick={handlePrev}
          >
            <img src={icons.arrowLeft} className="h-3" alt="Hành động" />
          </button>
        )}
        {!loadingSkeleton ? (
          <Swiper
            ref={sliderRef}
            spaceBetween={10}
            slidesPerView={4.6}
            breakpoints={{
              140: {
                slidesPerView: 1.3,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2.2,
                spaceBetween: 4,
              },
              768: {
                slidesPerView: 3.5,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            className="mySwiper w-full"
            onSlideChange={(swiper: any) => {
              const slidesPerView = Math.round(
                Number(swiper.params.slidesPerView)
              );
              const activeIndex = swiper.activeIndex;
              if (activeIndex === 0) {
                // Swiper đang ở slide đầu
                setEnableNav({ start: true, end: false });
              } else if (
                activeIndex ===
                swiper.slides.length - Number(slidesPerView)
              ) {
                // Swiper đang ở slide cuối
                setEnableNav({ start: false, end: true });
              } else {
                // Swiper đang ở giữa
                setEnableNav({ start: false, end: false });
              }
            }}
          >
            {listLottery?.length > 0 &&
              listLottery.map((lottery: any, index: any) => {
                return (
                  lottery.gameid !== 201 && (
                    <SwiperSlide key={index}>
                      <LotteryTodayItem
                        getReload={handleGetReload}
                        goGame={() => goGame(lottery?.desc)}
                        name={formatGetNameLottery(lottery?.gameid, listGames)}
                        image={lottery?.imageurl}
                        endtime={lottery?.endtime}
                        winnumber={lottery?.winnumber}
                        gameid={lottery?.gameid}
                      />
                    </SwiperSlide>
                  )
                );
              })}
          </Swiper>
        ) : (
          <div className="min-w-full flex items-center overflow-auto gap-2.5 no-scrollbar">
            {[1, 2, 3, 4, 5].map((item: any, index: number) => (
              <SkeletonLoading key={index} height={116} width={250} />
            ))}
          </div>
        )}
        {!isMobile && (
          <button
            className="min-w-9 h-9 p-2 bg-secondary/10 hover:bg-secondary/20 rounded-full flex items-center justify-center text-primary ml-2.5"
            type="button"
            onClick={handleNext}
          >
            <img src={icons.arrowRight} className="h-3" alt="Hành động" />
          </button>
        )}
      </div>
    </div>
  );
}
