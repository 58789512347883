import { useEffect, useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { replaceTitleCategory } from "utils/postFormat";
import { useDispatch, useSelector } from "react-redux";
import {
  convertNameLotteryToParam,
  handleFilterLotteryByDay,
  handleGroupMenuGames,
} from "utils/lotteryFormat";
import { checkIsPageUrl } from "utils/commonFormat";
import {
  addListGroupedMenuLottery,
  addSelectedDay,
} from "features/lotterySlice";
import { days } from "constant/lottery";

function MenuLotteryMegaComponent({ menu }: any) {
  const [openMenu, setOpenMenu] = useState(false);
  const { listGames, selectedDay } = useSelector((state: any) => state.lottery);
  const [menuLottery, setMenuLottery] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (listGames && listGames?.length > 0) {
      const groupedMenu = handleGroupMenuGames(listGames);
      setMenuLottery(groupedMenu);
      dispatch(addListGroupedMenuLottery(groupedMenu));
    }
  }, [listGames]);

  useEffect(() => {
    if (Object.keys(selectedDay)?.length === 0) {
      const dayOfWeek = new Date().getDay();
      const results = {
        north: dayOfWeek,
        central: dayOfWeek,
        south: dayOfWeek,
      };
      dispatch(addSelectedDay(results));
    }
  }, []);

  const handleHoverMenu = (value: boolean) => {
    setOpenMenu(value);
  };

  const handleDayChange = (region: string, day: number) => {
    dispatch(addSelectedDay({ ...selectedDay, [region]: day }));
  };
  return (
    <Menu
      placement="bottom-end"
      animate={{
        mount: { y: 0 },
        unmount: { y: 25 },
      }}
      open={openMenu}
      handler={handleHoverMenu}
      allowHover
      offset={15}
    >
      <MenuHandler>
        <Link
          className={`group text-md font-semibold hover:text-primary flex justify-start items-center gap-1 ${
            checkIsPageUrl(menu?.url, "xs") ? "text-primary" : "text-white"
          }`}
          to={menu?.url}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: menu?.icon,
            }}
            className={`group-hover:!fill-primary group-hover:!text-primary ${
              checkIsPageUrl(menu?.url, "xs")
                ? "!fill-primary !text-primary"
                : "!fill-white !text-white"
            }`}
          ></div>
          {menu?.title}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="13px"
            viewBox="0 -960 960 960"
            width="13px"
            className={`transition-transform ${
              openMenu ? "-rotate-90" : "rotate-90"
            } ${
              checkIsPageUrl(menu?.url, "xs") ? "!fill-primary" : "!fill-white"
            }`}
          >
            <path d="M579-480 285-774q-15-15-14.5-35.5T286-845q15-15 35.5-15t35.5 15l307 308q12 12 18 27t6 30q0 15-6 30t-18 27L356-115q-15 15-35 14.5T286-116q-15-15-15-35.5t15-35.5l293-293Z" />
          </svg>
        </Link>
      </MenuHandler>

      <MenuList
        onClick={(e) => e.stopPropagation()}
        className={` dark:bg-bgPrimary dark:border-borderPrimary w-full max-w-3xl lg:max-w-2xl rounded-xl flex justify-between items-start gap-2 outline-none border-none`}
        placeholder={undefined}
      >
        {menuLottery &&
          Object.entries(menuLottery).map(([groupName, items]: any) => {
            const region =
              groupName === "Xổ Số Miền Bắc"
                ? "north"
                : groupName === "Xổ Số Miền Trung"
                ? "central"
                : "south";
            return (
              <div
                key={groupName}
                className="flex flex-col space-y-2 col-span-1 outline-none border-none"
              >
                <div className="flex items-center justify-between gap-4">
                  <strong className="text-md font-semibold text-primary dark:text-primary">
                    {replaceTitleCategory(groupName)}
                  </strong>
                  <select
                    onClick={(e) => e.stopPropagation()}
                    value={String(selectedDay[region])}
                    onChange={(e) =>
                      handleDayChange(region, parseInt(e.target.value))
                    }
                    className="outline-none border-none px-1 rounded text-xs dark:text-white/70 dark:bg-bgPost_dark text-black/70 bg-[#f6f6f6]"
                  >
                    {days.map((day, index) => (
                      <option
                        onClick={(e) => e.stopPropagation()}
                        key={index}
                        value={String(day.id)}
                        className="text-black dark:text-white"
                      >
                        {day.desc}
                      </option>
                    ))}
                  </select>
                </div>
                {handleFilterLotteryByDay(selectedDay[region], items).map(
                  (item: any) => (
                    <Link
                      key={item.id}
                      to={`../ket-qua/${convertNameLotteryToParam(item.desc)}`}
                      className={`!outline-0 !border-none hover:text-primary`}
                    >
                      <MenuItem
                        className={` ${
                          checkIsPageUrl(
                            convertNameLotteryToParam(item.desc),
                            "menu"
                          )
                            ? "bg-blue-gray-50 bg-opacity-80 text-blue-gray-900 dark:bg-bgSecondary"
                            : ""
                        } dark:hover:bg-bgSecondary dark:focus:bg-bgSecondary dark:active:bg-bgSecondary dark:!text-white/70`}
                        placeholder={undefined}
                      >
                        {replaceTitleCategory(item.desc)}
                      </MenuItem>
                    </Link>
                  )
                )}
              </div>
            );
          })}
      </MenuList>
    </Menu>
  );
}

export default MenuLotteryMegaComponent;
