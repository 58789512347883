import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const listContent = [
  {
    id: 1,
    title: "Kết quả xổ số",
    content: `<p>Nhận miễn phí kết quả xổ số thông qua Telegram chỉ bằng 1 cú click.</p>
              <ul>
                  <li>- Cập nhật kết quả xổ số của tất cả các tỉnh thành.</li>
                  <li>- Xem ngay kết quả trực tiếp và nhận thông báo tự động.</li>
                  <li>- Dễ dàng tìm kiếm và tra cứu kết quả theo ngày tháng.</li>
              </ul>`,
  },
  {
    id: 2,
    title: "Lịch Thi Đấu",
    content: ` <ul>
                  <li>- Luôn cập nhật lịch thi đấu hot mỗi ngày.</li>
                  <li>- Cung cấp thông tin lịch thi đấu đầy đủ của các giải thể thao trong nước và quốc tế.</li>
                  <li>- Thời gian thi đấu chính xác và kết quả trực tiếp.</li>
              </ul>`,
  },
  {
    id: 3,
    title: "Tin Tức",
    content: `<ul>
                <li>- Không bỏ lỡ bất kỳ một thông tin mới nào.</li>
                <li>- Cập nhật nhanh chóng các sự kiện nóng hổi từ các nguồn tin uy tín.</li>
                <li>- Tin tức phân loại rõ ràng giúp bạn dễ dàng theo dõi những chủ đề yêu thích.</li>
            </ul>`,
  },
  // {
  //   id: 4,
  //   title: "Hỗ trợ trực tuyến 24/7",
  //   content: "Đội ngũ hỗ trợ nhiệt tình luôn trực 24/7.",
  // },
];

const Section2 = () => {
  const { isMobile, isTablet, mode } = useSelector(
    (state: any) => state.layout
  );
  return (
    <section
      className={classNames(
        "w-full flex flex-col items-center pb-[120px] pt-[50px] px-5",
        {
          "!pt-1 !pb-[100px]": isTablet,
          "!pt-4 !pb-[44px]": isMobile,
        }
      )}
    >
      <div className="flex items-center justify-center mb-[62px] relative">
        <img
          src={require(`assets/images/landingpage/pagexoso${
            isMobile ? "mobile.png" : "web.jpg"
          }`)}
          className="w-full max-w-[1080px] rounded-3xl"
          alt="pageLottery"
        />
        <div
          className={classNames(
            `w-full h-2/5 absolute bottom-0 left-0 ${
              mode === 1 ? "bg-shadow" : "bg-shadow-light"
            }`
          )}
        ></div>
      </div>
      <div
        className={classNames(
          "flex items-center justify-center mb-[120px] max-w-[60%]",
          {
            "!mb-10": isMobile || isTablet,
            "!max-w-full": isMobile,
          }
        )}
      >
        <h2
          className={classNames(
            "text-linear-primary font-semibold text-[32px] text-center",
            { "text-linear-primary-dark": mode === 0 }
          )}
        >
          Giao diện hiện đại, tra cứu kết quả xổ số nhanh chóng với công nghệ
          dẫn đầu Châu Á
        </h2>
      </div>
      <div
        className={classNames("flex items-start justify-between gap-10", {
          "flex-col !items-start !gap-0": isTablet || isMobile,
          "!w-full": isMobile,
        })}
      >
        {listContent.map((item: any, index: number) => (
          <div
            className={classNames("flex flex-col", {
              "mt-2": index > 0 && (isTablet || isMobile),
            })}
            key={index}
          >
            <h4 className="text-black dark:text-white font-semibold mb-2">
              {item.title}
            </h4>
            <p
              className="text-sm text-text-secondary text-wrap break-words w-[90%]"
              dangerouslySetInnerHTML={{
                __html: item.content,
              }}
            ></p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Section2;
