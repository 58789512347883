export const ltOpenDay = [
  "200,126,139,131,123,124,125,201", // Chủ Nhật
  "200,126,127,105,106,107,201", // Thứ 2
  "200,128,129,109,108,110,201", // Thứ 3
  "200,130,131,111,112,113,201", // Thứ 4
  "200,132,133,134,114,115,116,201", // Thứ 5
  "200,135,136,117,118,119,201", // Thứ 6
  "200,137,138,130,105,121,122,201,120", // Thứ 7
];
export const days = [
  { id: 1, desc: "Thứ 2" },
  { id: 2, desc: "Thứ 3" },
  { id: 3, desc: "Thứ 4" },
  { id: 4, desc: "Thứ 5" },
  { id: 5, desc: "Thứ 6" },
  { id: 6, desc: "Thứ 7" },
  { id: 0, desc: "Chủ Nhật" },
];
