import classNames from "classnames";
import { listSportLeague } from "constant";
import { useSelector } from "react-redux";

const MenuLeague = ({ choiceLeague, onClose, leagueActive }: any) => {
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <h2 className="text-black/40 dark:text-white/40 text-base font-semibold uppercase">
          Giải đấu
        </h2>
        {(isMobile || isTablet) && (
          <img
            src={require("assets/icons/close.png")}
            className="w-6 h-6"
            alt="close"
            onClick={onClose}
          />
        )}
      </div>
      <div className="flex flex-col">
        {listSportLeague?.map((league: any, index: number) => (
          <div
            className={classNames(
              "flex items-center justify-between bg-surface-tertiary dark:bg-bgPrimary hover:text-success-500 dark:hover:text-success-500 text-black text-sm font-semibold dark:text-white rounded-md px-2.5 py-2 cursor-pointer mt-2",
              {
                "!bg-success-500 hover:!text-black dark:hover:!text-white":
                  league.id === leagueActive?.id,
              }
            )}
            onClick={() => choiceLeague(league)}
            key={index}
          >
            <img src={league.icon} className="w-6 h-6 mr-2" alt="league" />
            <span className="w-full">{league?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuLeague;
