import axiosInstance from "./axiosInstance";

const thirdpartyApi = {
  loginIBC(params: any) {
    const url = "api/v1/gameaccess/ibc/login";
    return axiosInstance.post(url, {}, { params });
  },
  //Events
  getListMatchSportEvent(params?: any) {
    const url = "/api/v1/gameaccess/public/ibc/marketing/events";
    return axiosInstance.post(url, params);
  }, //Events
  getListLeaguesSportEvent(params?: any) {
    const url = "/api/v1/gameaccess/public/ibc/marketing/leagues";
    return axiosInstance.post(url, params);
  },
};

export default thirdpartyApi;
