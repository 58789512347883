import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import authSlice from "features/authSlice";
import lotterySlice from "features/lotterySlice";
import livestreamSlice from "features/livestreamSlice";
import userSlice from "features/userSlice";
import layoutSlice from "features/layoutSlice";
import chatSlice from "features/chatSlice";
import socketSlice from "features/socketSlice";
import commonSlice from "features/commonSlice";
import minigamesSlice from "features/minigamesSlice";
import seoSlice from "features/seoSlice";
import postSlice from "features/postSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authSlice,
  lottery: lotterySlice,
  livestream: livestreamSlice,
  user: userSlice,
  layout: layoutSlice,
  chat: chatSlice,
  socket: socketSlice,
  common: commonSlice,
  minigames: minigamesSlice,
  seo: seoSlice,
  post: postSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;
