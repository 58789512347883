import classNames from "classnames";
import LotteryTodaySection from "components/LotteryTodaySection/LotteryTodaySection";
import SideBar from "components/SideBar/SideBar";
import { toggleSidebar } from "features/layoutSlice";
import Header from "partials/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { postRoutes } from "routes/routes";

function PostLayout() {
  const { isMobile, isTablet, isMiniDesktop, showSidebar } = useSelector(
    (state: any) => state.layout
  );
  const { configSeo } = useSelector((state: any) => state.seo);

  const dispatch = useDispatch();

  const handleToggleSidebar = (status: boolean) => {
    dispatch(toggleSidebar(status));
  };

  return (
    <div
      className={classNames(
        "main-layout w-full min-h-screen flex dark:bg-bgLayout bg-surface-primary",
        {
          "!bg-surface-primary dark:!bg-bgPrimary": isMobile,
        }
      )}
    >
      <h1 className="opacity-0 hidden">{configSeo?.title}</h1>
      {(isMobile || isTablet || isMiniDesktop) && (
        <div className="sidebar z-30">
          <SideBar />
          <div
            className={classNames(
              "bg-black/40 opacity-0 transition-all duration-150 invisible ease-out fixed top-0 left-0 w-full h-full -z-[10]",
              {
                "!z-30 opacity-80 duration-150 ease-linear !visible":
                  showSidebar,
              }
            )}
            onClick={() => handleToggleSidebar(false)}
          ></div>
        </div>
      )}
      <div className="content-page w-full flex flex-col items-center min-h-screen">
        <div
          className={classNames("main-header w-full h-[84px]", {
            "!h-[56px]": isMobile,
          })}
        >
          <div className="fixed z-30 w-full min-w-[100vw]">
            <Header />
          </div>
        </div>

        <div
          className={classNames(
            "content-home w-full h-full flex flex-col items-center flex-1"
          )}
        >
          <div className="w-full flex justify-center p-3 bg-bgPrimary relative z-10">
            <LotteryTodaySection />
          </div>
          <div
            className={`flex justify-center w-full ${
              isTablet ? "p-4 h-full" : isMobile ? "" : "py-2 pb-10 px-3 h-full"
            } max-w-[1440px] ${
              isMobile ? "bg-surface-primary dark:bg-bgPrimary" : ""
            }`}
          >
            <div className="w-full max-w-[1280px]">
              <Routes>
                {postRoutes?.map((route, index) => {
                  const SubPage = route.component;
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={<SubPage />}
                    />
                  );
                })}
                <Route path="*" element={<Navigate to={"/"} replace />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostLayout;
