import userApi from "apis/userApi";
import store from "app/store";
import { listIdLotteryByRegion } from "constant";
import { addBalanceUser } from "features/userSlice";
import { ltOpenDay } from "interfaces/list-lottery";

export const formatBetContent = (value: string) => {
  return value?.slice(value.indexOf("[") + 1, value.indexOf("]"));
};

export const formatGetTitleStatus = (
  value: number | string,
  arr: any,
  type?: string
) => {
  const statusFind = arr?.find((item: any) => item.id === value);
  const result = type === "title" ? statusFind?.desc : statusFind?.id;
  return result;
};

export const checkInvalidValue = (value: any) => {
  if (value) {
    if (!value || value === "" || value === null) {
      return false;
    } else {
      return true;
    }
  }
};

export const getTitleTransStatus = (arr: any, key: number) => {
  const statusFind = arr?.find((item: any) => item.id === key);
  return statusFind?.desc;
};

export const formatArraygameid = (arr: any) => {
  if (arr?.length > 0) {
    return arr?.map((id: number) => `gameid=${id}`).join("&");
  }
};

const handleCheckMethod1K = (methodStr: string, method: string) => {
  return [methodStr, methodStr + "1K"].filter((x) => x === method).length > 0;
};

export const formatPrizeNumberByMethod = (
  methodtype: string,
  index: number,
  prize: number,
  numberPrize: string
) => {
  let result = numberPrize;
  if (
    [
      "LO2SO",
      "LO2SO1K",
      "XIEN2",
      "XIEN3",
      "XIEN4",
      "LOTRUOT4SO",
      "LOTRUOT8SO",
      "LOTRUOT10SO",
    ].filter((x) => x === methodtype).length > 0 ||
    (handleCheckMethod1K("DEDACBIET", methodtype) && index === 0) ||
    (handleCheckMethod1K("DEGIAINHAT", methodtype) && index === 1) ||
    (handleCheckMethod1K("DEDAUDUOI", methodtype) &&
      (index === 8 || index === 0)) ||
    (handleCheckMethod1K("DEDAU", methodtype) && index === 8)
  ) {
    result = appendDigits(2, numberPrize);
  } else if (
    (handleCheckMethod1K("LO2SODAU", methodtype) && numberPrize.length > 2) ||
    (handleCheckMethod1K("DEDACBIETDAU", methodtype) && index === 0) ||
    (handleCheckMethod1K("DEGIAINHATDAU", methodtype) && index === 1)
  ) {
    result = appendDigits(2, numberPrize, true);
  } else if (handleCheckMethod1K("DEGIAIBAY", methodtype) && index === 7) {
    result = appendDigits(2, numberPrize);
  } else if (
    handleCheckMethod1K("LO3SO", methodtype) ||
    (handleCheckMethod1K("3CANGDACBIET", methodtype) && index === 0) ||
    (handleCheckMethod1K("3CANGDAUDUOIMB", methodtype) &&
      (index === 0 || index === 6)) ||
    (handleCheckMethod1K("3CANGDAUDUOI", methodtype) &&
      (index === 0 || index === 7))
  ) {
    if (numberPrize.length > 2) {
      result = appendDigits(3, numberPrize);
    }
  } else if (
    (handleCheckMethod1K("3CANGDAUMB", methodtype) && index === 6) ||
    (handleCheckMethod1K("3CANGDAU", methodtype) && index === 7)
  ) {
    result = appendDigits(3, numberPrize, true);
  } else if (
    (handleCheckMethod1K("4CANGDACBIET", methodtype) && index === 0) ||
    handleCheckMethod1K("LO4SO", methodtype)
  ) {
    if (numberPrize.length > 3) {
      result = appendDigits(4, numberPrize);
    }
  } else if (handleCheckMethod1K("DAU", methodtype) && index === 0) {
    result = appendDigits(0, numberPrize, false, 2);
  } else if (handleCheckMethod1K("DUOI", methodtype) && index === 0) {
    result = appendDigits(0, numberPrize, false, 1);
  }
  return result;
};

const appendDigits = (
  digits: number,
  str: string,
  isDau?: boolean,
  index?: number
) => {
  let html = "";
  if (typeof index !== "undefined") {
    const arr = str.split(""),
      getIdxVal = arr[arr?.length - index];
    html = '<b class="text-error-500 font-semibold">' + getIdxVal + "</b>";
    arr[arr?.length - index] = html;
    return arr.join("");
  }
  if (isDau) {
    const getDigit = str.substring(0, digits);
    const getRecentDigit = str.substr(digits);
    html =
      '<b class="text-error-500 font-semibold">' +
      getDigit +
      "</b>" +
      getRecentDigit;
  } else if (str?.length >= digits) {
    const getDigit = str?.substr(-digits);
    const getRecentDigit = str?.substring(0, str?.length - digits);
    html =
      getRecentDigit +
      '<b class="text-error-500 font-semibold">' +
      getDigit +
      "</b>";
  } else {
    html = str;
  }
  return html;
};

export const formatGeneratePrizeLottery = (
  result: string,
  prizeNum: number
) => {
  if (result?.length > 0) {
    const splitString: string[] = result?.split(/,/g);
    return splitString[prizeNum - 1]?.trim();
  } else {
    return "";
  }
};

export const formatGetNameLottery = (gameid: number, arr: any) => {
  const nameLotteryFind = arr?.find((lot: any) => gameid === lot.id);
  return nameLotteryFind?.desc;
};

export const formatGetFlagMethod = (listMethod: any, methodid: number) => {
  const flagMethodFind = listMethod?.find(
    (lot: any) => methodid === lot.methodid
  );
  return flagMethodFind?.checkflag;
};

export const formatStringWinnumberToResultData = (
  gameid: number,
  result: string
) => {
  const arrResult = result?.split(",");
  let resultData: any = [];
  const sortOrder = [
    "giaidb",
    "giainhat",
    "giainhi",
    "giaiba",
    "giaitu",
    "giainam",
    "giaisau",
    "giaibay",
    "giaitam",
  ];
  if ([210, 200, 201].includes(Number(gameid))) {
    resultData = sortOrder.map((item: string, index: number) => {
      let value: string = "";
      const key = item;
      if (arrResult?.length > 0) {
        if (key === "giaidb") {
          value = `${arrResult[0] || ""}`;
        } else if (key === "giainhat") {
          value = `${arrResult[1] || ""}`;
        } else if (key === "giainhi") {
          value = `${arrResult[2] || ""},${arrResult[3] || ""}`;
        } else if (key === "giaiba") {
          value = `${arrResult[4] || ""},${arrResult[5] || ""},${
            arrResult[6] || ""
          },${arrResult[7] || ""},${arrResult[8] || ""},${arrResult[9] || ""}`;
        } else if (key === "giaitu") {
          value = `${arrResult[10] || ""},${arrResult[11] || ""},${
            arrResult[12] || ""
          },${arrResult[13] || ""}`;
        } else if (key === "giainam") {
          value = `${arrResult[14] || ""},${arrResult[15] || ""},${
            arrResult[16] || ""
          },${arrResult[17] || ""},${arrResult[18] || ""},${
            arrResult[19] || ""
          }`;
        } else if (key === "giaisau") {
          value = `${arrResult[20] || ""},${arrResult[21] || ""},${
            arrResult[22] || ""
          }`;
        } else if (key === "giaibay") {
          value = `${arrResult[23] || ""},${arrResult[24] || ""},${
            arrResult[25] || ""
          },${arrResult[26] || ""}`;
        }
      }
      return { key, value };
    });
  } else {
    resultData = sortOrder.map((item: string, index: number) => {
      let value: string = "";
      const key = item;
      if (arrResult?.length > 0) {
        if (key === "giaidb") {
          value = arrResult[0] || "";
        } else if (key === "giainhat") {
          value = arrResult[1] || "";
        } else if (key === "giainhi") {
          value = `${arrResult[2] || ""}`;
        } else if (key === "giaiba") {
          value = `${arrResult[3] || ""},${arrResult[4] || ""}`;
        } else if (key === "giaitu") {
          value = `${arrResult[5] || ""},${arrResult[6] || ""},${
            arrResult[7] || ""
          },${arrResult[8] || ""},${arrResult[9] || ""},${
            arrResult[10] || ""
          },${arrResult[11] || ""}`;
        } else if (key === "giainam") {
          value = `${arrResult[12] || ""}`;
        } else if (key === "giaisau") {
          value = `${arrResult[13] || ""},${arrResult[14] || ""},${
            arrResult[15] || ""
          }`;
        } else if (key === "giaibay") {
          value = `${arrResult[16] || ""}`;
        } else if (key === "giaitam") {
          value = `${arrResult[17] || ""}`;
        }
      }
      return { key, value };
    });
  }
  const newResultData = resultData.reduce((result: any, item: any) => {
    result[item.key] = item.value;
    return result;
  }, {});
  return newResultData;
};

// export const formatStringWinnumberToDauDuoiData = (result: string) => {
//   const arrResult = result?.split(",");
//   let resultData: any = [];
//   const sortOrder = [
//     "0",
//     "1",
//     "2",
//     "3",
//     "4",
//     "5",
//     "6",
//     "7",
//     "8",
//     "9",
//   ];
//   const newResultData = resultData.reduce((result: any, item: any) => {
//     result[item.key] = item.value;
//     return result;
//   }, {});
//   return newResultData;
// };

export const handleFormatDauDuoi = (winnumber: any, method: string) => {
  const keys = [
    "giaidb",
    "giainhat",
    "giainhi",
    "giaiba",
    "giaitu",
    "giainam",
    "giaisau",
    "giaibay",
    "giaitam",
  ];
  let result = "";
  if (winnumber && typeof winnumber !== "string") {
    keys.forEach((key, index) => {
      if (winnumber[key]) {
        result += winnumber[key]?.replace(" ", "");
        if (index !== keys.length - 1) {
          result += ",";
        }
      }
    });
  } else {
    result = winnumber;
  }
  const arrNum = result?.replaceAll(" ", "").split(",");
  const arrTwoLastDigit = [];
  const arrGet: any[] = [];
  if (method === "LO2SODAU") {
    for (let i = 0, len = arrNum?.length; i < len; i++) {
      if (arrNum[i].length > 2 && arrNum[i].substring(0, 2) !== "") {
        arrTwoLastDigit.push(arrNum[i].substring(0, 2));
      }
    }
  } else {
    for (let i = 0, len = arrNum?.length; i < len; i++) {
      if (arrNum[i].substr(-2) !== "") {
        arrTwoLastDigit.push(arrNum[i].substr(-2));
      }
    }
  }

  for (let i = 0; i < 10; i++) {
    arrGet[i] = [];
    for (let j = 0, len = arrTwoLastDigit?.length; j < len; j++) {
      const getFirstNum = Number(arrTwoLastDigit[j].split("")[0]);
      const getLastNum = Number(arrTwoLastDigit[j].split("")[1]);
      if (i === getFirstNum) {
        arrGet[i].push(getLastNum);
      }
    }
  }
  const newArrayRender = arrGet.map((item: any, index: number) => ({
    key: index,
    value: item.join(","),
  }));
  return newArrayRender;
};

export const handleFormatDuoiDau = (winnumber: any, method?: string) => {
  const keys = [
    "giaidb",
    "giainhat",
    "giainhi",
    "giaiba",
    "giaitu",
    "giainam",
    "giaisau",
    "giaibay",
    "giaitam",
  ];
  let result = "";
  if (winnumber && typeof winnumber !== "string") {
    keys.forEach((key, index) => {
      if (winnumber[key]) {
        result += winnumber[key]?.replace(" ", "");
        if (index !== keys.length - 1) {
          result += ",";
        }
      }
    });
  } else {
    result = winnumber;
  }
  const arrNum = result?.replaceAll(" ", "").split(",");
  const arrTwoLastDigit = [];
  const arrGet: any[] = [];
  for (let i = 0, len = arrNum?.length; i < len; i++) {
    if (arrNum[i].substr(-2) !== "") {
      arrTwoLastDigit.push(arrNum[i].substr(-2));
    }
  }

  for (let i = 0; i < 10; i++) {
    arrGet[i] = [];
    for (let j = 0, len = arrTwoLastDigit?.length; j < len; j++) {
      const getFistNum = Number(arrTwoLastDigit[j].split("")[0]);
      const getLastNum = Number(arrTwoLastDigit[j].split("")[1]);
      if (i === getLastNum) {
        arrGet[i].push(getFistNum);
      }
    }
  }
  const newArrayRender = arrGet?.map((item: any, index: number) => ({
    key: index,
    value: item.join(","),
  }));
  return newArrayRender;
};

export const handleGroupDauDuoi = (listDauDuoi: any, listDuoiDau: any) => {
  const mergeArray = listDauDuoi?.map((item1: any) => {
    const item2 = listDuoiDau?.find((item: any) => item.key === item1.key);
    return {
      key: item1.key,
      dau: item1.value,
      duoi: item2 ? item2.value : "",
    };
  });
  return mergeArray;
};

export const handleCheckDauDuoiHighlight = (
  value: any,
  datahover: any,
  dataselect?: any,
  listDataHover?: any,
  listDataSelect?: any
) => {
  let strHTML: string = "";
  const styleHightlight =
    "background-color: #22C55E; margin-left: 1px; transition: transform 0.5s ease-linear; transform: scale(1); padding: 1.5px 3px; display: flex; align-items: center;justify-content: center;border-radius: 8px;color: #ffffff;";
  const firstPart: string = value?.slice(0, -2);
  const lastPart: string = value?.slice(-2);
  //hover array value
  const arrValueDauHover = datahover?.data?.dau
    ?.split(/,/g)
    .map((str: string) => {
      return str + datahover?.data?.key;
    });
  const arrValueDuoiHover = datahover?.data?.duoi
    ?.split(/,/g)
    .map((str: string) => {
      return datahover?.data?.key + str;
    });

  //select array value
  const arrValueDauSelect = dataselect?.dau?.split(/,/g).map((str: string) => {
    return str + dataselect?.key;
  });
  const arrValueDuoiSelect = dataselect?.duoi
    ?.split(/,/g)
    .map((str: string) => {
      return dataselect?.key + str;
    });

  //list hover array value
  const arrValueDauListHover = listDataHover?.dau
    ?.split(/,/g)
    .map((str: string) => {
      return str + listDataHover?.key;
    });
  const arrValueDuoiListHover = listDataHover?.duoi
    ?.split(/,/g)
    .map((str: string) => {
      return listDataHover?.key + str;
    });

  //list select array value
  const arrValueDauListSelect = listDataSelect?.dau
    ?.split(/,/g)
    .map((str: string) => {
      return str + listDataSelect?.key;
    });
  const arrValueDuoiListSelect = listDataSelect?.duoi
    ?.split(/,/g)
    .map((str: string) => {
      return listDataSelect?.key + str;
    });

  const shouldHighlightHover = (part: string) => {
    switch (datahover?.type) {
      case "dau":
        return arrValueDauHover?.includes(part);
      case "duoi":
        return arrValueDuoiHover?.includes(part);
      case "both":
        return (
          arrValueDauHover?.includes(part) || arrValueDuoiHover?.includes(part)
        );
      default:
        return false;
    }
  };

  const shouldHighlightSelect = (part: string) => {
    switch (dataselect?.type) {
      case "dau":
        return arrValueDauSelect?.includes(part);
      case "duoi":
        return arrValueDuoiSelect?.includes(part);
      case "both":
        return (
          arrValueDauSelect?.includes(part) ||
          arrValueDuoiSelect?.includes(part)
        );
      default:
        return false;
    }
  };

  const shouldHightlightListHover = (part: string) => {
    switch (listDataHover?.type) {
      case "dau":
        return arrValueDauListHover?.includes(part);
      case "duoi":
        return arrValueDuoiListHover?.includes(part);
      case "both":
        return (
          arrValueDauListHover?.includes(part) ||
          arrValueDuoiListHover?.includes(part)
        );
      default:
        return false;
    }
  };

  const shouldHightlightListSelect = (part: string) => {
    switch (listDataSelect?.type) {
      case "dau":
        return arrValueDauListSelect?.includes(part);
      case "duoi":
        return arrValueDuoiListSelect?.includes(part);
      case "both":
        return (
          arrValueDauListSelect?.includes(part) ||
          arrValueDuoiListSelect?.includes(part)
        );
      default:
        return false;
    }
  };

  const isHighlighted =
    shouldHighlightHover(lastPart) ||
    shouldHighlightSelect(lastPart) ||
    shouldHightlightListHover(lastPart) ||
    shouldHightlightListSelect(lastPart);

  strHTML = isHighlighted
    ? firstPart + `<span style="${styleHightlight}">${lastPart}</span>`
    : value;

  return strHTML ? strHTML : value;
};

export const findRegionByGameId = (gameid: number) => {
  const region = listIdLotteryByRegion?.find((region) =>
    region?.listIdLotterys?.includes(gameid)
  );
  return region ? region?.name : null;
};

export const handleCheckDauDuoiHightLightByNumber = (
  value: any,
  num: string | null,
  numberHover: any
) => {
  const firstPart: string = value?.slice(0, -2);
  const secondLastChar = value.charAt(value.length - 2);
  const fisrtLastChar = value.charAt(value.length - 1);
  const lastPart: string = value?.slice(-2);
  const styleHightlight =
    "background-color: #22C55E; margin-left: 1px; transition: transform 0.5s ease-linear; transform: scale(1); padding: 1.5px 3px; display: flex; align-items: center;justify-content: center;border-radius: 8px;color: #ffffff;";
  return secondLastChar === num ||
    fisrtLastChar === num ||
    secondLastChar === numberHover ||
    fisrtLastChar === numberHover
    ? firstPart + `<span style="${styleHightlight}">${lastPart}</span>`
    : value;
};

export //Đầu đuôi
const handleConvertDauDuoi = (arr: any) => {
  const listDauDuoiConvert =
    arr?.length > 0 &&
    arr?.map((item: any) => {
      const listDau = handleFormatDauDuoi(
        formatStringWinnumberToResultData(item.gameid, item.winnumber),
        "LO2SO"
      );
      const listDuoi = handleFormatDuoiDau(
        formatStringWinnumberToResultData(item.gameid, item.winnumber),
        "LO2SO"
      );
      return handleGroupDauDuoi(listDuoi, listDau);
    });
  return listDauDuoiConvert;
};

export const formatCodesEasyWatch = (codes: string) => {
  return codes?.replaceAll(/&/g, "-");
};

export const formatCodeBreakWord = (str: string, count: number) => {
  const pairs = str?.split(","); // Tách chuỗi thành mảng các cặp số

  const formattedString = pairs
    .map((pair, index) => {
      if ((index + 1) % count === 0) {
        return pair + ",\n";
      } else {
        return pair + ",";
      }
    })
    .join("");

  return formattedString;
};

export const numberOfPrizes = (
  region: string,
  prizes: string,
  typeSlice?: string
): any => {
  if (typeSlice === "3so") {
    return 3;
  } else if (typeSlice === "2so") {
    return 2;
  }
  if (region === "mb") {
    const normalizedPrizes = prizes.normalize("NFC");
    switch (normalizedPrizes.toLowerCase()) {
      case "giaitam":
      case "giaibay":
        return 2;
      case "giaisau":
        return 3;
      case "giainam":
      case "giaitu":
        return 4;
      case "giaiba":
      case "giainhi":
      case "giainhat":
      case "dacbiet":
        return 5;
      default:
        return 5;
    }
  } else {
    const normalizedPrizes = prizes.normalize("NFC");
    switch (normalizedPrizes.toLowerCase()) {
      case "giaitam":
        return 2;
      case "giaibay":
        return 3;
      case "giaisau":
      case "giainam":
        return 4;
      case "giaitu":
      case "giaiba":
      case "giainhi":
      case "giainhat":
        return 5;
      case "dacbiet":
        return 6;
      default:
        return 5;
    }
  }
};

export const convertNameLotteryToParam = (str: string) => {
  const paramConvert = str
    ?.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/\s+/g, "-")
    .trim();
  return paramConvert;
};

// export const formatResultDataToWinnumber = (
//   region: string,
//   resultData: any
// ): string => {
//   const sortOrder = [
//     "giaidb",
//     "giainhat",
//     "giainhi",
//     "giaiba",
//     "giaitu",
//     "giainam",
//     "giaisau",
//     "giaibay",
//     "giaitam",
//   ];

//   let winnumber = sortOrder.map((prize) => {
//     const length = numberOfPrizes(region, prize);
//     const prizeValue = resultData[prize as keyof any];
//     if (prizeValue) {
//       const prizeArray = prizeValue.split(",");
//       return prizeArray
//         .slice(0, length)
//         .map((val: string) => val.trim())
//         .join(",");
//     }
//     return Array(length).fill("").join(",");
//   });

//   return winnumber.join(",");
// };

export const formatResultDataToWinnumber = (
  region: string,
  resultData: any,
  prizesMTMN: {
    numNum: number;
    title: string;
    dataKey: string;
    length: number;
    subPrizes: number;
    stt: number;
  }[]
): string => {
  // const sortedPrizes = prizesMTMN;

  const sortedPrizes = prizesMTMN.sort((a, b) => {
    return a.numNum - b.numNum;
  });
  let winnumber = sortedPrizes.map((prize) => {
    const length = prize.subPrizes;
    let prizeValue = resultData[prize.dataKey as keyof any];

    // Kiểm tra nếu prizeValue là null hoặc undefined, thay thế bằng các chuỗi rỗng dựa theo length
    if (prizeValue == null) {
      prizeValue = Array(length).fill("").join(",");
    } else {
      const prizeArray = prizeValue.split(",");
      prizeValue = prizeArray
        .slice(0, length)
        .map((val: string) => val.trim())
        .join(",");
    }

    return prizeValue;
  });

  return winnumber.join(",");
};

export const handleGetBalanceUser = async () => {
  try {
    const { data } = await userApi.getBalanceUser();
    if (data) {
      store.dispatch(addBalanceUser(data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleGroupMenuGames = (listGames: any) => {
  try {
    return listGames.reduce(
      (acc: any, current: any) => {
        const { type } = current;
        const newKey =
          String(type) === "1"
            ? "Xổ Số Miền Nam"
            : String(type) === "2"
            ? "Xổ Số Miền Trung"
            : String(type) === "3"
            ? "Xổ Số Miền Bắc"
            : String(type);
        if (!acc[newKey]) {
          acc[newKey] = [];
        }
        acc[newKey].push(current);
        return acc;
      },
      {
        "Xổ Số Miền Nam": [],
        "Xổ Số Miền Trung": [],
        "Xổ Số Miền Bắc": [],
      }
    );
  } catch (error) {
    console.error("Error grouping menu games:", error);
    return {
      "Xổ Số Miền Nam": [],
      "Xổ Số Miền Trung": [],
      "Xổ Số Miền Bắc": [],
    };
  }
};

export const handleFilterLotteryByDay = (dayIndex: number, items: any[]) => {
  const idsForDay = ltOpenDay[dayIndex]?.split(",") || [];
  if (dayIndex === 7) {
    return items;
  } else {
    return items.filter((item: any) => idsForDay?.includes(String(item?.id)));
  }
};

export function getDayDescById(array: any, id: any) {
  return array.find((day: any) => day.id === id)?.desc || "Tất cả";
}

// Kiểm tra nếu tất cả các mảng đều rỗng
export const isAllEmpty = (data: Record<string, any[]>): boolean => {
  return Object?.values(data)?.every((array) => array?.length === 0) === false;
};
