import classNames from "classnames";
import { listDays } from "constant";

const ListDaysPopover = ({ getDay }: any) => {
  const handleSelectDay = (day: number) => {
    getDay(day);
  };
  return (
    <div className="flex flex-col p-1 rounded-lg overflow-hidden shadow-lg bg-blue-gray-50 dark:bg-divider-dark">
      {listDays.map((day: any, index: number) => (
        <span
          className={classNames(
            "text-[13px] font-medium text-black/60 hover:text-black/60 dark:hover:text-white dark:text-white/50 whitespace-nowrap py-0.5 pl-2 mt-1 cursor-pointer",
            { "!mt-0": index === 0 }
          )}
          onClick={() => handleSelectDay(day.id)}
          key={index}
        >
          {day.desc}
        </span>
      ))}
    </div>
  );
};

export default ListDaysPopover;
