import WrapLeague from "./WrapLeague";

const ListMatchComponent = (props: any) => {
  const { data, loading, leagueActive } = props;

  return (
    <div className="flex flex-col">
      <WrapLeague
        data={data}
        loading={loading}
        leagueActive={leagueActive}
      ></WrapLeague>
    </div>
  );
};

export default ListMatchComponent;
