import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import "./DateTimePicker.scss";

import { formatDateSmall } from "utils/commonFormat";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { formatDateTimeBasic } from "utils/formateDate";

const months = [
  "Tháng 1",
  "Tháng 2",
  "Tháng 3",
  "Tháng 4",
  "Tháng 5",
  "Tháng 6",
  "Tháng 7",
  "Tháng 8",
  "Tháng 9",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12",
];

const dayWeeks = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

const DateTimePicker = (props: any) => {
  const { onClose, onSubmit, valueDate } = props;

  const selectYearRef = useRef<any>();
  const selectMonthRef = useRef<any>();
  const selectHourRef = useRef<any>();
  const selectMinuteRef = useRef<any>();
  const selectSecondRef = useRef<any>();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const currentMonthIndex = currentDate.getMonth();

  const [hoursArr, setHoursArr] = useState<any>([]);
  const [minutesArr, setMinutesArr] = useState<any>([]);
  const [yearsArr, setyearsArr] = useState<any>([]);
  const [datesArr, setDatesArr] = useState<any>([]);
  const [valueMonth, setValueMonth] = useState<string>();

  const [selectedMonth, setSelectedMonth] = useState<any>(currentMonth);
  const [selectedYear, setSelectedYear] = useState<any>(
    new Date().getFullYear()
  );
  const [selectedDay, setSelectedDay] = useState<any>(currentDay);

  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);
  const [dateTime, setDateTime] = useState<string>("");
  const [typeShowSelect, setTypeShowSelect] = useState<any>({
    isShow: false,
    type: "",
  });

  useEffect(() => {
    const hourArray = Array.from({ length: 24 }, (_, i) => i);
    const minuteArray = Array.from({ length: 60 }, (_, i) => i);
    const years = [];
    for (let i = currentYear; i >= currentYear - 10; i--) {
      years.push(i);
    }

    setSelectedMonth(currentMonthIndex + 1);
    setValueMonth(months[currentMonthIndex]);
    setyearsArr(years);
    setHoursArr(hourArray);
    setMinutesArr(minuteArray);
    setDatesArr(createDaysArray());
  }, []);

  useEffect(() => {
    if (valueDate) {
      DateTimeSplit(valueDate);
    }
  }, [valueDate]);

  const DateTimeSplit = (datetimeString: any) => {
    if (datetimeString && datetimeString?.length > 0 && valueDate?.length > 0) {
      const [datePart, timePart] = datetimeString?.split(" ");
      const [day, month, year] = datePart?.split("/");
      const [hours, minutes, seconds] = timePart?.split(":");
      setSelectedYear(Number(year));
      setSelectedMonth(Number(month));
      setSelectedDay(Number(day));
      setHour(Number(hours));
      setMinute(Number(minutes));
      setSecond(Number(seconds));
    }
  };


  useEffect(() => {
    setDatesArr(createDaysArray());
    setValueMonth(months[selectedMonth - 1]);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    const datetime = `${selectedYear}-${formatDateSmall(
      selectedMonth
    )}-${formatDateSmall(selectedDay)}T${formatDateSmall(
      hour
    )}:${formatDateSmall(minute)}:${formatDateSmall(second)}`;
    setDateTime(datetime);
  }, [hour, minute, second, selectedDay, selectedMonth, selectedYear]);

  const createDaysArray = (): any => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const firstDayOfMonth = new Date(
      selectedYear,
      selectedMonth - 1,
      1
    ).getDay();
    const daysArray = [];

    // Thêm các ngày của tháng trước
    const prevMonthDays = new Date(
      selectedYear,
      selectedMonth - 1,
      0
    ).getDate();
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      daysArray.push({
        day: prevMonthDays - i,
        disabled: true,
        isSelected: false,
        monthCurrent: selectedMonth - 1 === 0 ? 12 : selectedMonth - 1,
        yearCurrent: selectedMonth - 1 === 0 ? selectedYear - 1 : selectedYear,
      });
    }

    // Thêm các ngày của tháng hiện tại
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push({
        day: i,
        disabled: false,
        isSelected:
          i === currentDay &&
          selectedMonth === currentMonth &&
          selectedYear === currentYear,
        monthCurrent: selectedMonth,
        yearCurrent: selectedYear,
      });
    }

    // Thêm các ngày của tháng sau
    let remainingDays = 42 - daysArray.length;
    let nextMonthFirstDay = (firstDayOfMonth + daysInMonth) % 7;
    let nextMonthDays = nextMonthFirstDay === 0 ? 0 : 7 - nextMonthFirstDay;
    if (remainingDays > nextMonthDays) {
      remainingDays -= nextMonthDays;
    } else {
      nextMonthDays = remainingDays;
      remainingDays = 0;
    }
    for (let i = 1; i <= nextMonthDays; i++) {
      daysArray.push({
        day: i,
        disabled: true,
        isSelected: false,
        monthCurrent: selectedMonth + 1 === 13 ? 1 : selectedMonth + 1,
        yearCurrent: selectedMonth + 1 === 13 ? selectedYear + 1 : selectedYear,
      });
    }
    while (remainingDays > 0) {
      nextMonthFirstDay = (nextMonthFirstDay + nextMonthDays) % 7;
      nextMonthDays = nextMonthFirstDay === 0 ? 7 : 7 - nextMonthFirstDay;
      let daysToAdd = Math.min(remainingDays, nextMonthDays);
      for (let i = 1; i <= daysToAdd; i++) {
        daysArray.push({
          day: i,
          disabled: true,
          isSelected: false,
          monthCurrent: selectedMonth + 1 === 13 ? 1 : selectedMonth + 1,
          yearCurrent:
            selectedMonth + 1 === 13 ? selectedYear + 1 : selectedYear,
        });
      }
      remainingDays -= daysToAdd;
    }
    return daysArray;
  };

  const handleChangeTime = (type: string, value: any) => {
    if (type === "hour") setHour(value);
    if (type === "minute") setMinute(value);
    if (type === "second") setSecond(value);
    if (type === "day") {
      const updatedDaysArray = datesArr.map((item: any) => ({
        ...item,
        isSelected: item.day === value,
      }));
      setDatesArr(updatedDaysArray);
      setSelectedDay(parseInt(value));
    }
    if (type === "month") {
      setSelectedMonth(months.indexOf(value) + 1);
      setValueMonth(value);
    }
    if (type === "year") setSelectedYear(parseInt(value));
  };

  const handlePrevNextClick = (direction: string) => {
    if (direction === "prev") {
      setSelectedMonth((prevMonth: number) => {
        if (prevMonth === 1) {
          setSelectedYear((prevYear: number) => prevYear - 1);
          return 12;
        } else {
          return prevMonth - 1;
        }
      });
    } else {
      setSelectedMonth((prevMonth: number) => {
        if (prevMonth === 12) {
          setSelectedYear((prevYear: number) => prevYear + 1);
          return 1;
        } else {
          return prevMonth + 1;
        }
      });
    }
  };

  const handleShowSelectByType = (type: string) => {
    setTypeShowSelect({ isShow: !typeShowSelect?.isShow, type: type });
  };

  const handleGetToday = () => {
    setSelectedDay(currentDay);
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
    setHour(currentDate.getHours());
    setMinute(currentDate.getMinutes());
    setSecond(currentDate.getSeconds());
  };

  return (
    <React.Fragment>
      {/* <!-- Datepicker --> */}
      <div
        id="with-time-tab-preview-datepicker"
        className="w-80 flex flex-col border shadow-lg rounded-xl bg-white dark:bg-bgPrimary border-borderLightPrimary dark:border-borderPrimary"
      >
        <div className="p-3">
          {/* <!-- Calendar --> */}
          <div className="space-y-0.5">
            {/* <!-- Months --> */}
            <div className="grid grid-cols-5 items-center gap-x-3 mx-1.5 pb-3">
              {/* <!-- Prev Button --> */}
              <div className="col-span-1">
                <button
                  type="button"
                  className="size-8 flex justify-center items-center rounded-full disabled:opacity-50 disabled:pointer-events-none text-text-secondary hover:text-gray-800 dark:text-white dark:hover:text-white/40"
                  onClick={() => handlePrevNextClick("prev")}
                  disabled={
                    selectedYear === currentYear - 10 && selectedMonth === 1
                  }
                >
                  <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m15 18-6-6 6-6"></path>
                  </svg>
                </button>
              </div>
              {/* <!-- End Prev Button --> */}

              {/* <!-- Month / Year --> */}
              <div className="col-span-3 flex justify-center items-center gap-x-1">
                <div className="relative" ref={selectMonthRef}>
                  <span
                    onClick={() => handleShowSelectByType("month")}
                    className="flex text-nowrap w-full cursor-pointer text-start font-medium focus:outline-none text-black dark:text-white hover:text-opacity-80 bg-transparent before:absolute before:inset-0 before:z-[1]"
                  >
                    {valueMonth}
                  </span>
                  <CustomSelect
                    parentRef={selectMonthRef}
                    isShow={
                      typeShowSelect?.type === "month"
                        ? typeShowSelect?.isShow
                        : false
                    }
                    onClose={() =>
                      setTypeShowSelect({ isShow: false, type: "month" })
                    }
                    options={months}
                    onChange={(value: any) => handleChangeTime("month", value)}
                    value={selectedMonth}
                  />
                </div>

                <span className="text-black dark:text-white">/</span>
                <div className="relative" ref={selectYearRef}>
                  <span
                    onClick={() => handleShowSelectByType("year")}
                    className="flex text-nowrap w-full cursor-pointer text-start font-medium focus:outline-none text-black dark:text-white hover:text-opacity-80 bg-transparent before:absolute before:inset-0 before:z-[1]"
                  >
                    {selectedYear}
                  </span>
                  <CustomSelect
                    parentRef={selectYearRef}
                    isShow={
                      typeShowSelect?.type === "year"
                        ? typeShowSelect?.isShow
                        : false
                    }
                    onClose={() =>
                      setTypeShowSelect({ isShow: false, type: "year" })
                    }
                    options={yearsArr}
                    onChange={(value: any) => handleChangeTime("year", value)}
                    value={selectedYear}
                  />
                </div>
              </div>
              {/* <!-- End Month / Year --> */}

              {/* <!-- Next Button --> */}
              <div className="col-span-1 flex justify-end">
                <button
                  type="button"
                  className="size-8 flex justify-center items-center rounded-full disabled:opacity-50 disabled:pointer-events-none text-text-secondary hover:text-gray-800 dark:text-white dark:hover:text-white/40"
                  onClick={() => handlePrevNextClick("next")}
                  disabled={
                    selectedYear === currentYear && selectedMonth === 12
                  }
                >
                  <svg
                    className="flex-shrink-0 size-4"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6"></path>
                  </svg>
                </button>
              </div>
              {/* <!-- End Next Button --> */}
            </div>
            {/* <!-- Months --> */}

            {/* <!-- Weeks --> */}
            <div className="flex pb-1.5">
              {dayWeeks.map((day: string, index: number) => (
                <span
                  className="m-px w-10 block text-center text-sm text-text-secondary dark:text-white/40"
                  key={index}
                >
                  {day}
                </span>
              ))}
            </div>
            {/* <!-- Weeks --> */}

            {/* <!-- Days --> */}
            <div className="flex flex-wrap">
              {datesArr?.map((date: any, index: number) => (
                <div
                  className={classNames(
                    "m-px size-10 flex justify-center items-center border border-transparent text-sm text-black dark:text-white hover:border-primary hover:text-primary rounded-full cursor-pointer",
                    {
                      "text-text-secondary dark:!text-white/40 line-through !pointer-events-none":
                        date.disabled,
                      "!text-white bg-primary !border-primary ":
                        date.day === selectedDay && !date.disabled,
                      "!border-primary !text-primary":
                        date.day === currentDay &&
                        date.monthCurrent === currentMonth &&
                        date.yearCurrent === currentYear &&
                        date.day !== selectedDay,
                    }
                  )}
                  onClick={() => handleChangeTime("day", date.day)}
                  key={index}
                >
                  {date.day}
                </div>
              ))}
            </div>

            {/* <!-- Days --> */}
          </div>
          {/* <!-- End Calendar --> */}

          {/* <!-- Time --> */}
          <div className="mt-3 flex justify-center items-center gap-x-2">
            {/* <!-- Select Hours --> */}
            <div className="relative" ref={selectHourRef}>
              <span
                onClick={() => handleShowSelectByType("hour")}
                className="py-1 px-2 pe-6 flex text-nowrap w-full cursor-pointer border bg-white dark:bg-bgPrimary border-borderLightPrimary dark:border-borderPrimary text-black dark:text-white rounded-lg text-start text-sm focus:border-primary before:absolute before:inset-0 before:z-[1]"
              >
                {formatDateSmall(hour)}
              </span>
              <div className="absolute top-1/2 end-2 -translate-y-1/2">
                <svg
                  className="flex-shrink-0 size-3 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m7 15 5 5 5-5"></path>
                  <path d="m7 9 5-5 5 5"></path>
                </svg>
              </div>
              <CustomSelect
                parentRef={selectHourRef}
                isShow={
                  typeShowSelect?.type === "hour"
                    ? typeShowSelect?.isShow
                    : false
                }
                onClose={() =>
                  setTypeShowSelect({ isShow: false, type: "hour" })
                }
                options={hoursArr}
                onChange={(value: any) => handleChangeTime("hour", value)}
                value={hour ? formatDateSmall(hour) : formatDateSmall(0)}
              />
            </div>
            {/* <!-- End Select Hours --> */}

            <span className="text-black dark:text-white">:</span>

            {/* <!-- Select Minutes --> */}

            <div className="relative" ref={selectMinuteRef}>
              <span
                onClick={() => handleShowSelectByType("minute")}
                className="py-1 px-2 pe-6 flex text-nowrap w-full cursor-pointer border bg-white dark:bg-bgPrimary border-borderLightPrimary dark:border-borderPrimary text-black dark:text-white rounded-lg text-start text-sm focus:border-primary before:absolute before:inset-0 before:z-[1]"
              >
                {formatDateSmall(minute)}
              </span>
              <div className="absolute top-1/2 end-2 -translate-y-1/2">
                <svg
                  className="flex-shrink-0 size-3 text-gray-500 dark:text-neutral-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m7 15 5 5 5-5"></path>
                  <path d="m7 9 5-5 5 5"></path>
                </svg>
              </div>
              <CustomSelect
                parentRef={selectMinuteRef}
                isShow={
                  typeShowSelect?.type === "minute"
                    ? typeShowSelect?.isShow
                    : false
                }
                onClose={() =>
                  setTypeShowSelect({ isShow: false, type: "minute" })
                }
                options={minutesArr}
                onChange={(value: any) => handleChangeTime("minute", value)}
                value={minute ? formatDateSmall(minute) : formatDateSmall(0)}
              />
            </div>

            {/* <!-- End Select Minutes --> */}

            <span className="text-black dark:text-white">:</span>

            {/* <!-- Select Seconds --> */}
            <div className="relative" ref={selectSecondRef}>
              <span
                onClick={() => handleShowSelectByType("second")}
                className="py-1 px-2 pe-6 flex text-nowrap w-full cursor-pointer border bg-white dark:bg-bgPrimary border-borderLightPrimary dark:border-borderPrimary text-black dark:text-white rounded-lg text-start text-sm focus:border-primary before:absolute before:inset-0 before:z-[1]"
              >
                {formatDateSmall(second)}
              </span>
              <div className="absolute top-1/2 end-2 -translate-y-1/2">
                <svg
                  className="flex-shrink-0 size-3 text-gray-500 dark:text-neutral-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m7 15 5 5 5-5"></path>
                  <path d="m7 9 5-5 5 5"></path>
                </svg>
              </div>
              <CustomSelect
                parentRef={selectSecondRef}
                isShow={
                  typeShowSelect?.type === "second"
                    ? typeShowSelect?.isShow
                    : false
                }
                onClose={() =>
                  setTypeShowSelect({ isShow: false, type: "second" })
                }
                options={minutesArr}
                onChange={(value: any) => handleChangeTime("second", value)}
                value={second ? formatDateSmall(second) : formatDateSmall(0)}
              />
            </div>
            {/* <!-- End Select Seconds --> */}
          </div>
          {/* <!-- End Time --> */}
        </div>

        {/* <!-- Footer --> */}
        <div className="flex justify-between items-center gap-x-2 p-3 border-t border-borderLightPrimary dark:border-borderPrimary">
          <button
            type="button"
            className="inline-flex text-sm justify-center items-center h-9 w-fit px-2.5 py-2 text-primary hover:opacity-80 capitalize"
            onClick={handleGetToday}
          >
            Hôm nay
          </button>
          <div className="flex items-center">
            <button
              type="button"
              className="bg-secondary/20 flex items-center justify-center text-primary hover:opacity-80 h-9 w-fit px-2.5 py-2 rounded-[10px] text-sm font-semibold"
              onClick={onClose}
            >
              Hủy
            </button>
            <button
              type="button"
              className="inline-flex ml-2 text-sm items-center justify-center h-9 w-fit px-2.5 py-2 bg-primary hover:bg-primary/80 rounded-[10px] text-white border border-transparent leading-none capitalize"
              onClick={() => onSubmit(dateTime)}
            >
              Xác nhận
            </button>
          </div>
        </div>
        {/* <!-- End Footer --> */}
      </div>
      {/* <!-- End Datepicker --> */}
    </React.Fragment>
  );
};

export default DateTimePicker;
