import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    loading: false,
    loadingTopBar: false,
    listNotification: [],
    listGameTypePromo: [],
    listMenuTrees: [],
    listMenuMain: [],
    idSelectedMarquee: null,
    toast: { show: false, type: "", msg: "" },
    toastGlobal: { show: false, type: "", msg: "" },
    countInbox: null,
    modalPromoTimes: 0,
    showMenuMobile:false
  },
  reducers: {
    addListMenuMain(state, action) {
      state.listMenuMain = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLoadingTopBar(state, action) {
      state.loadingTopBar = action.payload;
    },
    addListNotification(state, action) {
      state.listNotification = action.payload;
    },
    addListGameTypePromo(state, action) {
      state.listGameTypePromo = action.payload;
    },
    addListMenuTrees(state, action) {
      state.listMenuTrees = action.payload;
    },
    addIdSelectedMarquee(state, action) {
      state.idSelectedMarquee = action.payload;
    },
    setShowToast(state, action) {
      state.toast = action.payload;
    },
    setShowToastGlobal(state, action) {
      state.toastGlobal = action.payload;
    },
    addCountInbox(state, action) {
      state.countInbox = action.payload;
    },
    addModalPromoTimes(state, action) {
      state.modalPromoTimes = action.payload;
    },
    addShowMenuMobile(state, action) {
      state.showMenuMobile = action.payload;
    },
  },
});

const { actions, reducer } = commonSlice;

export const {
  setLoading,
  addListNotification,
  addListMenuTrees,
  setShowToast,
  addCountInbox,
  addIdSelectedMarquee,
  addListGameTypePromo,
  addModalPromoTimes,
  setLoadingTopBar,
  setShowToastGlobal,
  addListMenuMain,
  addShowMenuMobile
} = actions;
export default reducer;
