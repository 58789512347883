import thirdpartyApi from "apis/thirdpartyApi";
import classNames from "classnames";
import LotteryTodaySection from "components/LotteryTodaySection/LotteryTodaySection";
import { listSportLeague } from "constant";
import Header from "partials/Header/Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, sortMatches } from "utils/commonFormat";
import { getCurrentWeekRange } from "utils/date";
import ListMatchComponent from "./components/ListMatchComponent";
import MenuLeague from "./components/MenuLeague";
import Modal from "components/Modal/Modal";
import { setConfigSeo } from "features/seoSlice";

const SportPage = () => {
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);
  const [listSport, setListSport] = useState<any[]>([]);
  const [leagueActive, setLeagueActive] = useState<any>();
  const [showModalMenu, setShowModalMenu] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const configSeo = useSelector((state: any) => state?.seo?.configSeo);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetListMatchHotEvent(listSportLeague[0]?.id);
    setLeagueActive(listSportLeague[0]);
  }, []);

  useEffect(() => {
    dispatch(
      setConfigSeo({
        ...configSeo,
        title: `${leagueActive?.name + " | " || ""}Lịch thi đấu`,
      })
    );
  }, [leagueActive]);

  const handleGetListMatchHotEvent = async (league: string) => {
    try {
      setLoading(true);
      const timer = getCurrentWeekRange();
      const { data } = await thirdpartyApi.getListMatchSportEvent({
        from: timer?.start,
        until: timer?.end,
        leagueName: league,
      });
      if (data) {
        const dataRes = data?.data;
        if (dataRes?.events?.length > 0) {
          const listSportLeagueKey = listSportLeague?.map((league: any) =>
            league?.id?.replace("*", "").toLowerCase()
          );
          const results = dataRes?.events
            .map((event: any) => {
              // Lọc và sắp xếp các market theo eventId
              event.leagueName = event?.leagueName?.replace("*", "");
              const details = dataRes?.markets
                ?.filter((market: any) => market?.eventId === event?.eventId)
                ?.sort((a: any, b: any) => a?.sort - b?.sort);
              // Tìm market có betType là 1 (Handicap)
              const handicap = details.find(
                (market: any) => market.betType === "1"
              );
              let handicapSelections = {
                betTypeName: handicap?.betTypeName || null,
                isLive: handicap?.isLive || null,
                home: null,
                away: null,
              };
              if (handicap) {
                handicapSelections.home = handicap?.selections.find(
                  (selection: any) => selection?.key === "h"
                );
                handicapSelections.away = handicap?.selections.find(
                  (selection: any) => selection?.key === "a"
                );
              }
              const overunder = details.find(
                (market: any) => market.betType === "3"
              );
              let overunderSelections = {
                betTypeName: overunder?.betTypeName || null,
                isLive: overunder?.isLive || null,
                home: null,
                away: null,
              };
              if (overunder) {
                overunderSelections.home = overunder?.selections.find(
                  (selection: any) => selection?.key === "h"
                );
                overunderSelections.away = overunder?.selections.find(
                  (selection: any) => selection?.key === "a"
                );
              }
              if (event) {
                event.isViewVideo = false;
                event.isPlay = false;
                event.leagueName = capitalizeFirstLetter(event.leagueName);
              }

              return listSportLeagueKey.includes(event.leagueName.toLowerCase())
                ? {
                    ...event,
                    details,
                    handicap: handicapSelections,
                    overunder: overunderSelections,
                  }
                : null;
            })
            .filter((item: any) => item != null);
          const dataFormat = sortMatches(results);

          setListSport(dataFormat);
        } else {
          setListSport([]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChoiceLeague = (league: any) => {
    handleGetListMatchHotEvent(league?.id);
    setLeagueActive(league);
    setShowModalMenu(false);
  };
  return (
    <div
      className={classNames(
        "main-layout w-full flex dark:bg-bgLayout bg-surface-primary",
        {
          "!bg-surface-primary dark:!bg-bgPrimary": isMobile,
        }
      )}
    >
      {" "}
      <div className="content-page w-full flex flex-col items-center min-h-screen">
        <div
          className={classNames("main-header w-full h-[84px]", {
            "!h-[56px]": isMobile,
          })}
        >
          <div className="fixed z-20 w-full min-w-[100vw]">
            <Header></Header>
          </div>
        </div>

        <div
          className={classNames(
            "content-home w-full h-full flex flex-col items-center flex-1"
          )}
        >
          <div className="w-full flex justify-center p-3 bg-bgPrimary relative z-10">
            <LotteryTodaySection />
          </div>
          <div
            className={`flex justify-center w-full ${
              isTablet ? "p-4 h-full" : isMobile ? "" : "py-8 px-3 h-full"
            } max-w-[1440px] ${
              isMobile ? "bg-surface-primary dark:bg-bgLayout" : ""
            }`}
          >
            <div
              className={classNames("w-full max-w-[1280px] flex", {
                "flex-col": isMobile || isTablet,
              })}
            >
              {!isMobile && !isTablet ? (
                <div className="flex flex-col w-[240px] mr-5">
                  <MenuLeague
                    choiceLeague={handleChoiceLeague}
                    leagueActive={leagueActive}
                  />
                </div>
              ) : (
                <div
                  className="flex items-center justify-between bg-surface-tertiary dark:bg-bgPrimary hover:opacity-80 text-black text-sm font-semibold dark:text-white rounded-md px-2.5 py-2 cursor-pointer sm:mx-0 sm:mt-0 m-3"
                  onClick={() => setShowModalMenu(true)}
                >
                  <img
                    src={leagueActive?.icon}
                    className="w-6 h-6 mr-2"
                    alt="league"
                  />
                  <span className="w-full">{leagueActive?.name}</span>
                </div>
              )}

              <div
                className={classNames("w-[calc(100%-260px)]", {
                  "!w-full": isMobile || isTablet,
                })}
              >
                <ListMatchComponent
                  data={listSport}
                  loading={loading}
                  leagueActive={leagueActive?.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isShow={showModalMenu} onClose={() => setShowModalMenu(false)}>
        <div className="bg-white dark:bg-bgLayout p-4 rounded-lg w-[90%]">
          <MenuLeague
            choiceLeague={handleChoiceLeague}
            onClose={() => setShowModalMenu(false)}
            leagueActive={leagueActive}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SportPage;
