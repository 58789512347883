import CommonLayout from "layout/CommonLayout";
import DetailPostLayout from "layout/DetailPostLayout";
import LandingPageLayout from "layout/LandingPageLayout";
import PostLayout from "layout/PostLayout";
import ResultLayout from "layout/ResultLayout";
import AllPostPage from "pages/AllPostPage/AllPostPage";
import CategoryPostPage from "pages/CategoryPostPage/CategoryPostPage";
import ContactPage from "pages/ContactPage/ContactPage";
import PostDetailPage from "pages/PostDetailPage/PostDetailPage";
import ResultHistoryPage from "pages/ResultHistoryPage/ResultHistoryPage";
import SportPage from "pages/SportPage/SportPage";
import TodayResultPage from "pages/TodayResultPage/TodayResultPage";

export const mainRoutes = [
  {
    path: "/ket-qua/*",
    component: ResultLayout,
    isMain: false,
    isPublic: true,
  }, {
    path: "/*",
    component: ResultLayout,
    isMain: false,
    isPublic: true,
  },
  {
    path: "/ve-chung-toi",
    component: LandingPageLayout,
    isMain: false,
    isPublic: true,
  },
  {
    path: "/trang/*",
    component: CommonLayout,
    isMain: false,
    isPublic: true,
  },
  {
    path: "danh-muc/*",
    component: PostLayout,
    isMain: true,
    isPublic: true,
  },
  {
    path: "tin-tuc/*",
    component: DetailPostLayout,
    isMain: true,
    isPublic: true,
  },
  {
    path: "/lich-thi-dau",
    component: SportPage,
    isMain: false,
    isPublic: true,
  },
];

export const resultRoutes = [
  {
    paths: [
      "/",
      "/truc-tiep-mien-bac",
      "/truc-tiep-mien-trung",
      "/truc-tiep-mien-nam",
    ],
    component: TodayResultPage,
    isMain: true,
    isPublic: true,
  },
  {
    path: "/:idLottery",
    component: ResultHistoryPage,
    isMain: true,
    isPublic: true,
  },
];

export const commonRoutes = [
  {
    path: "/lien-he-quang-cao",
    component: ContactPage,
    isMain: true,
    isPublic: true,
  },
];

export const postRoutes = [
  {
    path: "/",
    component: AllPostPage,
    isMain: true,
    isPublic: true,
  },
  {
    path: "/:slug",
    component: CategoryPostPage,
    isMain: true,
    isPublic: true,
  },
];

export const postDetailRoutes = [
  {
    path: "/:slug",
    component: PostDetailPage,
    isMain: true,
    isPublic: true,
  },
];
