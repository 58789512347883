import React, { Fragment, useEffect, useRef } from "react";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { listMenuPublic, listSocial } from "constant";
import classNames from "classnames";
import { addThemeMode } from "features/layoutSlice";
import { handleRedirectLink } from "utils/commonFormat";
import { icons } from "utils/icons";
import { addSelectedDay } from "features/lotterySlice";
import {
  convertNameLotteryToParam,
  getDayDescById,
  handleFilterLotteryByDay,
  handleGroupMenuGames,
  isAllEmpty,
} from "utils/lotteryFormat";
import { addListGroupedMenuLottery } from "features/lotterySlice";
import { replaceTitleCategory } from "utils/postFormat";
import ListDaysPopover from "components/Common/ListDaysPopover";
import { days } from "constant/lottery";

export function AsideMobile(props: any) {
  const { onClose, isShow } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mode } = useSelector((state: any) => state.layout);
  const listMenuMain = useSelector((state: any) => state.common.listMenuMain);
  const { listGames, selectedDay, listGroupedMenuLottery } = useSelector(
    (state: any) => state.lottery
  );
  const { configSeo } = useSelector((state: any) => state?.seo);

  const [open, setOpen] = React.useState(false);
  const [openPost, setOpenPost] = React.useState(false);

  const [openChild, setOpenChild] = React.useState({
    north: false,
    central: false,
    south: false,
  });

  const [selectDay, setSelectDay] = React.useState({
    north: false,
    central: false,
    south: false,
  });
  const popoverDayRefs: any = {
    north: useRef(null),
    central: useRef(null),
    south: useRef(null),
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      Object.keys(popoverDayRefs).forEach((region) => {
        if (
          popoverDayRefs[region].current &&
          !popoverDayRefs[region].current.contains(event.target)
        ) {
          setSelectDay((prev) => ({ ...prev, [region]: false }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverDayRefs]);

  useEffect(() => {
    if (listGames) {
      const groupedMenu = handleGroupMenuGames(listGames);
      dispatch(addListGroupedMenuLottery(groupedMenu));
    }
  }, [listGames]);

  useEffect(() => {
    if (Object.keys(selectedDay)?.length === 0) {
      const dayOfWeek = new Date().getDay();
      const results = {
        north: dayOfWeek,
        central: dayOfWeek,
        south: dayOfWeek,
      };
      dispatch(addSelectedDay(results));
    }
  }, []);

  const handleDayChange = (region: string, day: number) => {
    dispatch(addSelectedDay({ ...selectedDay, [region]: day }));
    setSelectDay({
      north: false,
      central: false,
      south: false,
    });
  };
  const closeDrawer = () => {
    onClose();
  };

  const handleNavigate = (path: any, isPublic?: boolean) => {
    navigate(path);
    onClose();
  };

  const handleToggleMode = (e: any) => {
    if (mode === 0) {
      dispatch(addThemeMode(1));
      localStorage.setItem("darkMode", "true");
    } else {
      dispatch(addThemeMode(0));
      localStorage.setItem("darkMode", "false");
    }
  };

  const toggleOpen = () => setOpen((cur) => !cur);
  const toggleOpenPost = () => setOpenPost((cur) => !cur);

  const toggleOpenChild = (region: any) => {
    setOpenChild((cur: any) => {
      return {
        ...Object.keys(cur).reduce((acc: any, key: any) => {
          acc[key] = key === region ? !cur[key] : false;
          return acc;
        }, {}),
      };
    });
  };
  const toggleOpenChildSelect = (region: any) => {
    setSelectDay((cur: any) => {
      return {
        ...Object.keys(cur).reduce((acc: any, key: any) => {
          acc[key] = key === region ? !cur[key] : false;
          return acc;
        }, {}),
      };
    });
  };
  return (
    <React.Fragment>
      <Drawer
        placement="right"
        open={isShow}
        onClose={closeDrawer}
        placeholder={undefined}
        className="flex flex-col justify-between items-center h-[100svh] dark:bg-bgLayout bg-surface-primary"
      >
        <div className="w-full">
          <div className="mb-2 flex items-center justify-between p-4">
            <img
              src={
                mode === 1
                  ? require("assets/images/logo.png")
                  : require("assets/images/logo-light.png")
              }
              className="h-8 w-auto"
              alt={configSeo?.title}
            />
            <button
              type="button"
              className="bg-primary/10 hover:bg-primary/30 p-1 rounded-lg border-none cursor-pointer"
              onClick={onClose}
            >
              <img
                src={require("assets/icons/close.png")}
                className="w-5 h-5"
                alt="close"
              />
            </button>
          </div>

          <List placeholder={undefined}>
            <div className="flex flex-col">
              {listMenuMain?.map((menu: any, index: number) => (
                <Fragment key={index}>
                  {menu?.key === "kqxs" ? (
                    <div>
                      <ListItem
                        className={`${
                          open &&
                          "bg-blue-gray-50 dark:bg-black/30 bg-opacity-80"
                        } dark:text-white/90 dark:active:bg-black/30 dark:hover:bg-black/30 dark:focus:bg-black/30`}
                        placeholder={undefined}
                        onClick={() => {
                          toggleOpen();
                        }}
                      >
                        <div
                          className="flex gap-0 items-center justify-start"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            if (menu?.isNewTab) {
                              handleRedirectLink(menu?.path || menu?.url);
                            } else {
                              handleNavigate(
                                menu?.path || menu?.url,
                                menu?.isPublic
                              );
                            }
                          }}
                        >
                          <ListItemPrefix placeholder={undefined}>
                            <div
                              className="h-5 w-5"
                              dangerouslySetInnerHTML={{ __html: menu.icon }}
                            />
                          </ListItemPrefix>
                          {menu.title}
                        </div>
                        <ListItemSuffix placeholder={undefined}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="13px"
                            viewBox="0 -960 960 960"
                            width="13px"
                            className={`transition-transform ${
                              open ? "-rotate-90" : "rotate-90"
                            } !fill-black/70 dark:!fill-white/70`}
                          >
                            <path d="M579-480 285-774q-15-15-14.5-35.5T286-845q15-15 35.5-15t35.5 15l307 308q12 12 18 27t6 30q0 15-6 30t-18 27L356-115q-15 15-35 14.5T286-116q-15-15-15-35.5t15-35.5l293-293Z"></path>
                          </svg>
                        </ListItemSuffix>
                      </ListItem>

                      <Collapse
                        className="shadow rounded-md"
                        style={{
                          overflow: Object.values(selectDay)?.some((day) => day)
                            ? "unset"
                            : "hidden",
                        }}
                        open={open}
                      >
                        <List className="ml-0" placeholder={menu?.title}>
                          {listGroupedMenuLottery &&
                            Object.entries(listGroupedMenuLottery)?.map(
                              ([groupName, items]: any) => {
                                // Determine the region based on the group name
                                const region =
                                  groupName === "Xổ Số Miền Bắc"
                                    ? "north"
                                    : groupName === "Xổ Số Miền Trung"
                                    ? "central"
                                    : "south";

                                const urlNav =
                                  region === "north"
                                    ? "./ket-qua/truc-tiep-mien-bac"
                                    : region === "central"
                                    ? "./ket-qua/truc-tiep-mien-trung"
                                    : "./ket-qua/truc-tiep-mien-nam";
                                return (
                                  <div className="relative" key={groupName}>
                                    <ListItem
                                      className={`${
                                        openChild[region] &&
                                        "bg-blue-gray-50 dark:bg-black/30 bg-opacity-80"
                                      } dark:text-white/90 dark:active:bg-black/30 dark:hover:bg-black/30 dark:focus:bg-black/30 py-2 flex justify-between items-center text-sm`}
                                      placeholder={replaceTitleCategory(
                                        groupName
                                      )}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        if (
                                          isAllEmpty(listGroupedMenuLottery)
                                        ) {
                                          toggleOpenChild(region);
                                        } else {
                                          handleNavigate(urlNav);
                                        }
                                      }}
                                    >
                                      <span
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                          handleNavigate(urlNav);
                                        }}
                                      >
                                        {replaceTitleCategory(groupName)}
                                      </span>

                                      {isAllEmpty(listGroupedMenuLottery) && (
                                        <ListItemSuffix
                                          className="flex gap-1 items-center justify-end"
                                          placeholder={undefined}
                                        >
                                          <span
                                            className="text-[12px]"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              toggleOpenChildSelect(region);
                                            }}
                                          >
                                            {getDayDescById(
                                              days,
                                              selectedDay[region]
                                            )}
                                          </span>

                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="11px"
                                            viewBox="0 -960 960 960"
                                            width="11px"
                                            className={`transition-transform ${
                                              openChild[region]
                                                ? "-rotate-90"
                                                : "rotate-90"
                                            } !fill-black/70 dark:!fill-white/70`}
                                          >
                                            <path d="M579-480 285-774q-15-15-14.5-35.5T286-845q15-15 35.5-15t35.5 15l307 308q12 12 18 27t6 30q0 15-6 30t-18 27L356-115q-15 15-35 14.5T286-116q-15-15-15-35.5t15-35.5l293-293Z"></path>
                                          </svg>
                                        </ListItemSuffix>
                                      )}
                                    </ListItem>

                                    {isAllEmpty(listGroupedMenuLottery) ? (
                                      <Fragment>
                                        <div
                                          ref={popoverDayRefs[region]}
                                          className={classNames(
                                            "absolute max-h-0 top-10 opacity-0 invisible right-0 z-50 transition-all duration-150 min-w-[84px]",
                                            {
                                              "!max-h-[9999px] !opacity-100 !visible":
                                                selectDay[region],
                                            }
                                          )}
                                        >
                                          <ListDaysPopover
                                            getDay={(value: number) =>
                                              handleDayChange(region, value)
                                            }
                                          />
                                        </div>

                                        <div>
                                          <Collapse open={openChild[region]}>
                                            <List
                                              className="ml-0 overflow-x-auto max-h-[190px]"
                                              placeholder={undefined}
                                            >
                                              {handleFilterLotteryByDay(
                                                selectedDay[region],
                                                items
                                              )?.map((item: any) => {
                                                return (
                                                  <ListItem
                                                    key={item?.desc}
                                                    onClick={() =>
                                                      handleNavigate(
                                                        "./ket-qua/" +
                                                          convertNameLotteryToParam(
                                                            item?.desc
                                                          )
                                                      )
                                                    }
                                                    className="!py-1.5 min-h-7 text-[13px] w-full h-auto dark:text-white/90 dark:active:bg-black/30 dark:hover:bg-black/30 dark:focus:bg-black/30 cursor-pointer"
                                                    placeholder={undefined}
                                                  >
                                                    {replaceTitleCategory(
                                                      item?.desc
                                                    )}
                                                  </ListItem>
                                                );
                                              })}
                                            </List>
                                          </Collapse>
                                        </div>
                                      </Fragment>
                                    ) : null}
                                  </div>
                                );
                              }
                            )}
                        </List>
                      </Collapse>
                    </div>
                  ) : (
                    <div>
                      <ListItem
                        className="dark:text-white/90 dark:active:bg-black/30 dark:hover:bg-black/30 dark:focus:bg-black/30"
                        key={index}
                        placeholder={undefined}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          toggleOpenPost();
                        }}
                      >
                        <div
                          className="flex items-center justify-start"
                          onClick={(e: any) => {
                            e.stopPropagation();

                            if (menu?.isNewTab) {
                              handleRedirectLink(menu?.path || menu?.url);
                            } else {
                              handleNavigate(
                                menu?.path || menu?.url,
                                menu?.isPublic
                              );
                            }
                          }}
                        >
                          <ListItemPrefix placeholder={undefined}>
                            <div
                              className="h-5 w-5"
                              dangerouslySetInnerHTML={{ __html: menu.icon }}
                            />
                          </ListItemPrefix>
                          {menu.title}
                        </div>
                        {menu?.listMenu && menu?.listMenu?.length > 0 && (
                          <ListItemSuffix placeholder={undefined}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="13px"
                              viewBox="0 -960 960 960"
                              width="13px"
                              className={`transition-transform ${
                                openPost ? "-rotate-90" : "rotate-90"
                              } !fill-black/70 dark:!fill-white/70`}
                            >
                              <path d="M579-480 285-774q-15-15-14.5-35.5T286-845q15-15 35.5-15t35.5 15l307 308q12 12 18 27t6 30q0 15-6 30t-18 27L356-115q-15 15-35 14.5T286-116q-15-15-15-35.5t15-35.5l293-293Z"></path>
                            </svg>
                          </ListItemSuffix>
                        )}
                      </ListItem>

                      {menu?.listMenu && menu?.listMenu?.length > 0 ? (
                        <Collapse className="shadow rounded-md" open={openPost}>
                          <List className="ml-0" placeholder={menu?.title}>
                            {menu?.listMenu?.map((item: any) => {
                              return (
                                <ListItem
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleNavigate(`../danh-muc/${item?.slug}`);
                                  }}
                                  className={`dark:text-white/90 dark:active:bg-black/30 dark:hover:bg-black/30 dark:focus:bg-black/30 py-2 flex justify-between items-center text-sm`}
                                  placeholder={undefined}
                                >
                                  {item?.name}
                                </ListItem>
                              );
                            })}
                          </List>
                        </Collapse>
                      ) : null}
                    </div>
                  )}
                </Fragment>
              ))}

              {listMenuPublic?.map((menu: any, index: number) => (
                <ListItem
                  className="dark:text-white/90 dark:active:bg-black/30 dark:hover:bg-black/30 dark:focus:bg-black/30F"
                  placeholder={undefined}
                  key={index}
                  onClick={() => {
                    if (menu.isNewTab) {
                      handleRedirectLink(menu.path);
                    } else {
                      handleNavigate(menu.path, menu.isPublic);
                    }
                  }}
                >
                  <ListItemPrefix placeholder={undefined}>
                    <div
                      className="h-5 w-5"
                      dangerouslySetInnerHTML={{ __html: menu.icon }}
                    />
                  </ListItemPrefix>
                  {menu.title}
                </ListItem>
              ))}
            </div>
          </List>
        </div>

        <div className="w-full py-3 px-5 border-t  border-gray-300/80 dark:border-borderPrimary flex items-center justify-between">
          <div className="flex items-center justify-start gap-4">
            {listSocial?.map((social: any, index: number) => (
              <div className="w-6 h-6 group cursor-pointer" key={index}>
                <img
                  src={
                    mode === 1 && social?.iconDarkDefault
                      ? social?.iconDarkDefault
                      : social.iconHover
                  }
                  className={classNames("w-full h-full  block")}
                  alt={configSeo?.title}
                />
              </div>
            ))}
          </div>

          <button
            className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/20 outline-none rounded-[10px]"
            type="button"
            onClick={handleToggleMode}
          >
            <img
              src={mode === 1 ? icons.sun : icons.moon}
              className="icon-header text-primary w-5 h-5"
              alt={configSeo?.title}
            />
          </button>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
