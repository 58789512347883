import { PostCommentByPost } from "interfaces/post.interface";
import axiosClient from "./axiosPostInstance";

const postApi = {
  getListCategories() {
    const url = "categories";
    return axiosClient.get(url);
  },
  getPostByCategories(params: any) {
    const url = "categories";
    return axiosClient.get(url, { params });
  },
  getListPost(params: any) {
    const url = "posts";
    return axiosClient.get(url, { params });
  },
  getCommentByPost(params: any) {
    const url = "comments";
    return axiosClient.get(url, { params });
  },
  postCommentByPost(payload: PostCommentByPost) {
    const url = "comments";
    return axiosClient.post(url, payload);
  },
};

export default postApi;
