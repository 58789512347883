// @material-tailwind-react
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

import { formatDistanceToNow } from "date-fns";
import { vi } from "date-fns/locale";
import { useEffect, useState } from "react";

export function PostItemComponent(props: any) {
  const { title, image, desc, href, date } = props;

  const [dateString, setDateString] = useState(date);

  useEffect(() => {
    const result = formatDistanceToNow(new Date(date), {
      addSuffix: true,
      locale: vi,
    });
    setDateString(result);
  }, [date]);

  return (
    <Card
      // shadow={false}
      className="border-none outline-none !shadow bg-white dark:bg-bgPost_dark rounded-[10px] dark:rounded-[10px] overflow-hidden"
      //   className="overflow-hidden border border-gray-300 shadow-sm"
      placeholder={title}
    >
      <CardBody className="p-0" placeholder={title}>
        <div className="w-full h-auto">
          <Link to={`/tin-tuc/${href}`} title={title}>
            <img
              key={title}
              src={image || require("../../assets/images/thumb_default.jpg")}
              className="h-auto max-h-40 md:max-h-full w-full min-w-full object-center rounded-tr-[10px] rounded-tl-[10px]  bg-transparent object-cover dark:text-white"
              alt={title}
            />
          </Link>
        </div>
        <div className="pb-3 md:pb-3 pt-1 px-3 dark:bg-bgPost_dark  md:pt-0 md:px-2">
          <Typography
            as={"div"}
            className="my-1 font-inter text-base md:text-xl font-semibold line-clamp-2 leading-5 md:leading-unset dark:bg-bgPost_dark text-title dark:text-white hover:text-primary dark:hover:text-primary"
            placeholder={title}
          >
            <Link to={`/tin-tuc/${href}`} title={title}>
              <h3
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              ></h3>
            </Link>
          </Typography>

          <Typography
            as="div"
            className="pt-1 w-full text-sm font-interflex flex-row "
            placeholder={undefined}
          >
            <div
              className=" !text-ellipsis !overflow-hidden !line-clamp-2 text-sm leading-5 mb-1 text-desc font-nomal dark:text-white/70"
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            ></div>

            <div className="flex text-[12px] gap-1 items-center font-medium justify-start pt-2 text-timer dark:text-white/40">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                viewBox="0 -960 960 960"
                width="16px"
                className="fill-timer dark:fill-white/40"
              >
                <path d="M520-496v-144q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v159q0 8 3 15.5t9 13.5l132 132q11 11 28 11t28-11q11-11 11-28t-11-28L520-496ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
              </svg>
              <span>{dateString}</span>
            </div>
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
}

export default PostItemComponent;
