import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertToTimestamp, formatToIOSDateSport } from "utils/date";

const ItemMatch = ({ data }: any) => {
  const { isMobile } = useSelector((state: any) => state.layout);

  const [countdown, setCountdown] = useState<{
    days: number;
    hours: number;
    minutes: number;
  }>({ days: 0, hours: 0, minutes: 0 });
  const [matchStatus, setMatchStatus] = useState<string>("Chưa bắt đầu"); // Trạng thái trận đấu

  useEffect(() => {
    const kickOffTime: any = new Date(data?.kickOffTime);
    kickOffTime.setHours(kickOffTime.getHours() + 11);
    const matchDuration = 90; // Thời gian trận đấu (phút)
    const matchEndTime = new Date(
      kickOffTime.getTime() + matchDuration * 60 * 1000
    ); // Thời gian kết thúc trận đấu
    if (kickOffTime) {
      const interval = setInterval(() => {
        const currentTime = new Date(); // Thời gian hiện tại

        if (currentTime >= matchEndTime) {
          setMatchStatus("Trận đấu đã kết thúc");
          clearInterval(interval); // Dừng interval khi trận đấu đã kết thúc
        } else if (currentTime >= kickOffTime) {
          setMatchStatus("Trận đấu đang diễn ra");
          const timeDiff = currentTime.getTime() - kickOffTime.getTime(); // Tính thời gian đã diễn ra
          const minutesPlayed = Math.floor(timeDiff / (1000 * 60)); // Chuyển đổi sang phút
          setCountdown({
            days: 0,
            hours: Math.floor(minutesPlayed / 60),
            minutes: minutesPlayed % 60,
          }); // Cập nhật phút đã chơi
        } else {
          setMatchStatus("Chưa bắt đầu");
          const timeDiff = kickOffTime.getTime() - currentTime.getTime(); // Tính thời gian còn lại
          const totalMinutes = Math.ceil(timeDiff / (1000 * 60)); // Chuyển đổi sang phút
          const days = Math.floor(totalMinutes / (60 * 24));
          const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
          const minutes = totalMinutes % 60;
          setCountdown({ days, hours, minutes });
        }
      }, 1000);

      return () => clearInterval(interval); // Dọn dẹp interval khi component unmount
    }
  }, [data]);

  return (
    <div className="w-full sm:px-0 px-2 flex items-start justify-between sm:flex-row flex-col pt-1 sm:pb-2 pb-4 border-b border-borderLightPrimary dark:border-borderPrimary">
      {isMobile ? (
        <div className="flex items-center justify-between w-full mb-2">
          <div className="date w-[120px]">
            <span className="text-sm font-normal text-text-secondary">
              {formatToIOSDateSport(data?.globalShowTime)}
            </span>
          </div>
          <div className="flex items-center whitespace-nowrap">
            {matchStatus === "Trận đấu đang diễn ra" && (
              <Fragment>
                <img
                  src={require("assets/icons/live.png")}
                  className="w-5 h-auto mr-2"
                  alt="live"
                />
                <span className="text-xs text-error-500">
                  {countdown.minutes}'
                </span>
              </Fragment>
            )}
            {matchStatus === "Chưa bắt đầu" && (
              <span className="text-xs text-warning-500">
                {countdown.days}d {countdown.hours}h {countdown.minutes}m còn
                lại
              </span>
            )}
            {matchStatus === "Trận đấu đã kết thúc" && (
              <span className="text-xs text-success-500">
                Trận đấu đã kết thúc
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className="date w-[120px]">
          <span className="text-sm font-normal text-text-secondary">
            {formatToIOSDateSport(data?.globalShowTime)}
          </span>
        </div>
      )}
      <div
        className={classNames("flex flex-col items-center w-[80%]", {
          "!w-full": isMobile,
        })}
      >
        <div className="flex items-center justify-center w-full">
          <div className="home-team flex items-center justify-end w-full">
            <span className="text-sm font-semibold text-black dark:text-white text-wrap text-start">
              {data?.teamInfo?.homeName}
            </span>
            <img
              src={data?.teamInfo?.homeIconUrl}
              className="w-6 h-6 ml-2"
              alt="home team"
            />
          </div>

          <div className="score-wrap w-[20%] mx-5 flex justify-center items-center">
            <div className="bg-success-500 rounded-3xl px-2 py-0.5 text-white text-xs font-semibold flex items-center justify-center w-full max-w-14 text-nowrap">
              {convertToTimestamp(formatToIOSDateSport(data?.kickOffTime)) >
              new Date().getTime()
                ? "VS"
                : `${data?.gameInfo?.liveHomeScore} - ${data?.gameInfo?.liveAwayScore}`}
            </div>
          </div>
          <div className="away-team flex items-center justify-start w-full">
            <img
              src={data?.teamInfo?.awayIconUrl}
              className="w-6 h-6 mr-2"
              alt="away team"
            />
            <span className="text-sm font-semibold text-black dark:text-white text-wrap w-[95%] text-start">
              {data?.teamInfo?.awayName}
            </span>
          </div>
        </div>
        <div></div>
      </div>
      {!isMobile && (
        <div className="flex items-center whitespace-nowrap w-[100px]">
          {matchStatus === "Trận đấu đang diễn ra" && (
            <Fragment>
              <img
                src={require("assets/icons/live.png")}
                className="w-5 h-auto mr-2"
                alt="live"
              />
              <span className="text-xs text-error-500">
                {countdown.minutes}'
              </span>
            </Fragment>
          )}
          {matchStatus === "Chưa bắt đầu" && (
            <span className="text-xs text-warning-500">
              {countdown.days}d {countdown.hours}h {countdown.minutes}m
            </span>
          )}
          {matchStatus === "Trận đấu đã kết thúc" && (
            <span className="text-xs text-success-500">
              Trận đấu đã kết thúc
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemMatch;
