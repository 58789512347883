import "./PostDetailPage.scss";
import postApi from "apis/postApi";
import BreadcrumbComponent from "components/BreadcrumbComponent/BreadcrumbComponent";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import PostDetailSkeleton from "./components/PostDetailSkeleton";
import RelatedPostComponent from "components/RelatedPostComponent/RelatedPostComponent";
import CommentComponent from "components/CommentComponent/CommentComponent";

function PostDetailPage() {
  const { slug } = useParams();

  const [listPost, setListPost] = useState<any>({});
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const { isMobile, isTablet, isMiniDesktop } = useSelector(
    (state: any) => state.layout
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const listGamesRef = useRef<HTMLDivElement>(null);
  const [offsetListGame, setOffsetListGame] = useState<number>(300);

  const [breadcrumbItems, setBreadcrumbItems] = useState<any[]>([
    { title: "Trang chủ", url: "/" },
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const newOffset = containerRef.current?.getBoundingClientRect().top;
        if (newOffset >= 84) {
          setOffsetListGame(newOffset);
        }
      }
    };
    window.document.addEventListener("scroll", handleScroll);
    return () => {
      window.document.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  useEffect(() => {
    handleGetListPostByCate(slug);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [slug]);

  const handleGetListPostByCate = async (id: any) => {
    try {
      if (!slug || !id) return;
      setLoading(true);
      const { data } = await postApi.getListPost({
        slug: id,
      });
      if (data && data?.length > 0) {
        setListPost(data[0]);
        setTitle(data[0]?.title?.rendered);
        setBreadcrumbItems([
          { title: "Trang chủ", url: "/" },
          {
            title: data[0]?.title?.rendered,
          },
        ]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {/* {!isMobile ? ( */}
      <div className="playback-page w-full h-full flex flex-col items-center overflow-x-hidden">
        <div className="flex w-full h-full relative justify-center">
          <div
            className={`flex justify-center flex-row md:flex-col-reverse w-full ${
              isTablet ? "p-4" : "p-2"
            } max-w-[1440px]`}
          >
            <div
              className={`h-full min-w-[256px] md:min-w-full md:mt-6 mr-4 md:mr-0 `}
            >
              <div
                className={` delay-100 transition-all duration-500`}
                style={{
                  top: offsetListGame,
                  position: isMobile || isTablet ? "unset" : "fixed",
                }}
                ref={listGamesRef}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 min-w-[256px] md:min-w-full md:max-w-full max-w-[256px]">
                  <div className="grid-col-span-1 w-full rounded-lg overflow-hidden">
                    <img
                      loading="eager"
                      src={require("assets/gifs/vnloto.gif")}
                      className="max-w-none w-full h-full object-cover"
                    />
                  </div>
                  <div className="grid-col-span-1 w-full rounded-lg overflow-hidden">
                    <img
                      src={require("assets/gifs/mig8.gif")}
                      className="max-w-none w-full h-full object-cover"
                      alt=""
                    />
                  </div>
                  <div className="grid-col-span-1 w-full rounded-lg overflow-hidden">
                    <img
                      src={require("assets/gifs/lixi.gif")}
                      className="max-w-none w-full h-full object-cover"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="content-page w-full h-full flex justify-center"
              ref={containerRef}
            >
              <div className="main-center-wrap w-full flex flex-col">
                <div className="pb-8 ">
                  {title && (
                    <div className="mb-6">
                      <h1
                        className="block antialiased tracking-normal text-4xl font-semibold leading-[1.1] text-inherit font-inter dark:text-white pb-1 md:text-xl"
                        dangerouslySetInnerHTML={{
                          __html: title,
                        }}
                      ></h1>
                      <div className="w-full">
                        <BreadcrumbComponent items={breadcrumbItems} />
                      </div>
                    </div>
                  )}
                  <div className="flex gap-4">
                    {loading ? (
                      <PostDetailSkeleton />
                    ) : (
                      <div className="flex flex-col gap-4 " id="content-post">
                        {listPost?.thumbnail_url && (
                          <img
                            src={listPost?.thumbnail_url}
                            className="h-auto w-full object-center"
                            alt={title}
                          />
                        )}

                        <div
                          className="!leading-6 mb-1 !font-inter text-black/80 dark:text-white/90"
                          dangerouslySetInnerHTML={{
                            __html: listPost?.content?.rendered,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>

                  <div className="mt-6">
                    <CommentComponent postId={listPost?.id} />
                  </div>
                </div>

                <RelatedPostComponent
                  postId={listPost?.id}
                  categoryId={listPost?.categories}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <div
          className={classNames(
            "content-mobile w-full h-full flex flex-col items-center relative"
          )}
        ></div>
      )} */}
    </>
  );
}

export default PostDetailPage;
