import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

import { formatDistanceToNow } from "date-fns";
import { vi } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function PostItemRowComponent(props: any) {
  const { title, image, desc, href, date } = props;

  const [dateString, setDateString] = useState(date);

  useEffect(() => {
    const result = formatDistanceToNow(new Date(date), {
      addSuffix: true,
      locale: vi,
    });
    setDateString(result);
  }, [date]);

  return (
    <Card
      // shadow={false}
      className="w-full min-h-60 md:min-h-auto flex-row lg:flex-col bg-bgPost dark:bg-bgPost_dark !shadow rounded-[10px] dark:rounded-[10px] overflow-hidden"
      placeholder={title}
    >
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 md:w-full w-auto lg:w-full h-80 lg:h-fit md:h-auto shrink-0 md:shrink-[unset] !rounded-none rounded-r-none bg-transparent"
        placeholder={undefined}
      >
        <Link to={`/tin-tuc/${href}`} title={title}>
          <img
            src={image || require("../../assets/images/thumb_default.jpg")}
            alt={title}
            className="md:h-auto h-full lg:w-full !max-w-full w-full object-cover object-center"
          />
        </Link>
      </CardHeader>
      <CardBody
        placeholder={undefined}
        className="px-6 py-3 md:pb-3 md:pt-0 md:px-2 flex flex-col justify-start items-start"
      >
          <Typography
            variant="h4"
            color="blue-gray"
            className="mb-1"
            placeholder={undefined}
          >
            <Link
              className="my-1 font-inter font-semibold text-xl  md:text-xl line-clamp-3 leading-7 md:leading-unset text-title dark:text-white hover:text-primary dark:hover:text-primary"
              to={`/tin-tuc/${href}`}
              title={title}
            >
              <h3
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              ></h3>
            </Link>
          </Typography>

          <Typography
            color="gray"
            className="mb-3 font-normal font-inter md:text-sm !text-ellipsis !overflow-hidden !line-clamp-4 leading-6 text-desc dark:text-white/70"
            placeholder={undefined}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            ></div>
          </Typography>

        <Typography
          as="div"
          className="w-full text-[13px] font-inter !text-ellipsis !overflow-hidden font-medium !line-clamp-3 leading-5 text-timer dark:text-white/40 !flex gap-1 items-center justify-start"
          placeholder={undefined}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16px"
            viewBox="0 -960 960 960"
            width="16px"
            className="fill-timer dark:fill-white/40"
          >
            <path d="M520-496v-144q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v159q0 8 3 15.5t9 13.5l132 132q11 11 28 11t28-11q11-11 11-28t-11-28L520-496ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
          </svg>
          <span>{dateString}</span>
        </Typography>
      </CardBody>
    </Card>
  );
}
export default PostItemRowComponent;
