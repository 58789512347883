import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import PostItemComponent from "components/PostItemComponent/PostItemComponent";
import { Fragment } from "react";
import { sliceArrayTo } from "utils/postFormat";
import PostItemSkeleton from "components/PostItemComponent/PostItemSkeleton";
import postApi from "apis/postApi";

function RelatedPostComponent({ postId, categoryId }: any) {
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRelatedPosts = async () => {
    try {
      setLoading(true);
      // Gọi API lấy bài viết liên quan
      const response = await postApi.getListPost({
        categories: categoryId?.join(","),
        exclude: postId,
        per_page: 10,
      });
      if (response?.data) setRelatedPosts(response?.data);
    } catch (error) {
      console.error("Error fetching related posts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRelatedPosts();
  }, [postId, categoryId]);

  return (
    <Fragment>
      {relatedPosts?.length > 0 && (
        <div className="mt-2">
          <Typography
            as="h1"
            variant="h2"
            className="font-inter text-3xl dark:text-white pb-2"
            //   relative after:absolute after:-bottom-1 after:left-0 after:bg-gradient-to-r after:from-[#3fc55d] after:to-[rgba(118,20,20,0)] after:max-w-[305px] after:w-full after:h-1
            placeholder={"Bài viết liên quan"}
          >
            Bài viết liên quan
          </Typography>

          <div className="grid grid-cols-3 lg:grid-cols-2 md:!grid-cols-1 gap-4">
            {loading ? (
              <Fragment>
                {Array.from({ length: 6 }, (_, index) => (
                  <PostItemSkeleton key={index} />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {sliceArrayTo(relatedPosts, 1, relatedPosts?.length) &&
                sliceArrayTo(relatedPosts, 1, relatedPosts?.length).length > 0
                  ? sliceArrayTo(relatedPosts, 1, relatedPosts?.length)?.map(
                      (post: any) => (
                        <div key={post?.id} className="col-span-1">
                          <PostItemComponent
                            title={post?.title?.rendered}
                            image={post?.thumbnail_url}
                            desc={post?.excerpt?.rendered}
                            href={post?.slug}
                            date={post?.date}
                          />
                        </div>
                      )
                    )
                  : null}
              </Fragment>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default RelatedPostComponent;
