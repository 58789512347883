import moment from "moment-timezone";
import { formatToIOString } from "./formateDate";

export const getCurrentDateTime = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
};

export const getStartDateTime = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).startOf("day").format("YYYY-MM-DD HH:mm:ss");
};

export const getEndDateTime = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).endOf("day").format("YYYY-MM-DD HH:mm:ss");
};

export const getCurrentDateTimeFormatBasic = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).format("YYYY-MM-DD");
};
export const getFormatDayMonthYear = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).format("DD-MM-YYYY");
};

export const getMidnightDateTimeFormatBasic = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).startOf("day").format("YYYY-MM-DD");
};

export const formatToIOSDateSport = (date: Date): string => {
  const sourceTimeZone = "UTC"; // Múi giờ gốc (GMT+0)
  const targetTimeZone = "Asia/Ho_Chi_Minh"; // Múi giờ đích (GMT+7)
  return moment
    .tz(date, sourceTimeZone)
    .tz(targetTimeZone)
    .format("HH:mm DD/MM");
};

export const convertToTimestamp = (dateString: string): number => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ GMT+7
  return moment.tz(dateString, "HH:mm DD/MM", timeZone).toDate().getTime();
};

export const formatToIOStringCustom = (date: Date): string => {
  const isoString = formatToIOString(date).replaceAll("T", " ");
  return isoString.split(".")[0];
};

export const getCurrentWeekRange = () => {
  try {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (7 - dayOfWeek));
    endOfWeek.setHours(23, 59, 59, 999);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - 12);
    startOfWeek.setHours(0, 0, 0, 0);
    return {
      start: formatToIOString(startOfWeek),
      end: formatToIOString(endOfWeek),
    };
  } catch (error) {}
};

export const formatDateTime = (value: string) => {
  const date = moment(value).format("DD/MM/yyyy HH:mm:ss");
  return date;
};
