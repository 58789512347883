import { createSlice } from "@reduxjs/toolkit";

export const postSlice = createSlice({
  name: "post",
  initialState: {
    listPostCache: [],
    allPostCache: {
      data: [],
      pagi: {
        pageCount: 0,
        currentPage: 0,
      },
    },
  },
  reducers: {
    setListPostCahce(state, action) {
      state.listPostCache = action.payload;
    },
    setAllPostCache(state, action) {
      state.allPostCache = action.payload;
    },
  },
});

const { actions, reducer } = postSlice;

export const { setListPostCahce, setAllPostCache } = actions;
export default reducer;
